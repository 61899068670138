import { t } from "i18next";
import { SearchTemplate } from "./SearchScript";

function SearchDateLayout(props) {
  let total = props.total;
  let startDate = props.startDate;
  let endDate = props.endDate;
  const formattedTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);

  // Remove currency symbol from the beginning and cents part from the end
  const displayTotal = formattedTotal.replace(/^(\D+)/, '').replace(/(\.00)$/, '');
  let showScoreTimestamp=localStorage.getItem("setScoreTimestamp");

  return (
    <>
      <div className="bxSBDate_Time">
        <div className="m-0 d-flex align-items-center">
          {showScoreTimestamp && (<span
            className="material-symbols-outlined searchPageBackBtn"
            title="Redirect to previous"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#bxOffcanvasPanel"
            onClick={() => SearchTemplate.redirectToPreviousPage()}
          >
            first_page
          </span>)}
          <span
            className="material-symbols-outlined me-2 text-success"
            id="bnfxtimeicon"
          >
            done
          </span>
          <div className="bnfxSearchTotal">
            {displayTotal}&nbsp;{t("name.result")}&nbsp;
          </div>
          <div id="Date_time_Search">
            ({startDate}&nbsp;{t("name.to")}&nbsp;{endDate})
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchDateLayout;
