import "../../dashboard/newdashboard.css";

function EventTypeStats(props) {
  let finalStats =props.data
  
  return (
    <>
      <div className="eventType_Stats data">
        <div className="row cards m-0">
          {finalStats?.map((card, index) => (
             <div className="col-sm-3" key={index}> 
              <div className="card eventcard">
                <div className="card-body eventcard p-2"> 
                  <div className="widgetText">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="text-uppercase text-white fw-normal eventdesc mb-0">
                        {card.description}
                      </h5>
                      <h3 className="text-white fw-bold eventcount mb-0">
                        {card.total}
                      </h3>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default EventTypeStats;
