import React, { useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { sql, PostgreSQL } from "@codemirror/lang-sql";
import { SearchTemplate } from "./SearchScript";
import { useGlobalState } from "../../components/common/GlobalVariables";
import { EditorView } from "@codemirror/view";
import Select from "react-select";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import DatePickerComponent from "../../components/common/calender/DatePickerComponent";
import SearchDropdown from "./SearchDropdown";

function SearchLoadPage(prop) {
  const autosuggestions = prop.autosuggestions;
  const sendButtonRef = useRef(null);
  const [querytext, setQueryText] = useGlobalState("global_search_value");
  const [isExpanded] = useGlobalState("search_collapsed");

  useEffect(() => {
    // Fetch query from localStorage
    const storedQuery = localStorage.getItem("setqueryStatement");
    if (storedQuery) {
      setQueryText(storedQuery); // Set state with the stored query
    }
  }, [setQueryText]);

  useEffect(() => {
    // Automatically trigger search on load if querytext exists
    if (querytext && sendButtonRef.current) {
      SearchTemplate.onChangeSql(querytext); // Set query in CodeMirror
      sendButtonRef.current.click(); // Trigger the search
      sendButtonRef.current = null;
    }
  }, [querytext]);

  
  return (
    <div className="bxRightSearchBox mt-3" id={"bxRightSearchBox"}>
      <div className="dropdown__info">
        <Select
          id="tablelist"
          className="form-control"
          options={SearchTemplate.tablesList}
          defaultValue={SearchTemplate.tablesList[0]}
          onChange={SearchTemplate.Onspecifictableonclick}
        ></Select>
      </div>
      <div className="sidebar-search">
        <div
          className="streamdownlaodicon"
          data-bs-toggle="modal"
          data-bs-target="#bxDownloadModal"
          onClick={() => {
            CommonTemplate.removedownloadnone();
            CommonTemplate.clearfillupdetails();
          }}
        >
          <span className="material-symbols-outlined ">download</span>
        </div>
        <button
          className={`btn saved_search_btn collapsed ${
            isExpanded ? "rotate" : ""
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSaveSearch"
          aria-expanded="false"
          aria-controls="collapseSaveSearch"
          onClick={() => SearchTemplate.handleToggle()}
        >
          <span className="material-symbols-outlined">expand_more</span>
        </button>
        <CodeMirror
          id="bnfxCodeMirrorGlobalSearch"
          value={querytext} // Set CodeMirror value to querytext
          placeholder={"Type query to search ..."}
          basicSetup={{
            lineNumbers: false,
            highlightActiveLineGutter: false,
            highlightActiveLine: false,
          }}
          theme={"dark"}
          minHeight="50px"
          extensions={[
            sql({
              defaultTable: "detections",
              dialect: PostgreSQL,
              schema: {
                detections: autosuggestions ? autosuggestions : [],
              },
            }),
            EditorView.lineWrapping,
          ]}
          onChange={(value) => setQueryText(value)} // Update querytext on change
        />

        <button
          className=" btn btn-primary "
          type="button"
          style={{ display: "none" }}
          id="search-loading-icon"
        >
          <div className="loading-icon">
            <div
              className="spinner-border"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                borderWidth: "0.1em", // Adjust this value to make the border thinner or thicker
              }}
            ></div>
          </div>
        </button>

        <button
          className=" btn btn-primary "
          type="button"
          id="search-sendbutton"
          ref={sendButtonRef} // Reference to trigger the click
          onClick={() => {
            const pagination = document.querySelector(
              "#bnfxSearchPagePagination"
            );
            const paginationdata = null;
            CommonTemplate.ContainerAvailability(pagination, paginationdata);
            SearchTemplate.getSearchPage(0, querytext);
          }}
        >
          <span className="material-symbols-outlined">send</span>
        </button>
      </div>
      <SearchDropdown></SearchDropdown>
      <div className=" p-right">
        <DatePickerComponent
          id="bnfxsearchDatePicker"
          onClickFn={SearchTemplate.renderSearchDatePicker}
          singleDate={false}
        />
      </div>
    </div>
  );
}

export default SearchLoadPage;
