import { t } from "i18next";

import { useEffect, useState } from "react";
import { Incidence } from "./IncidenceScript";
import moment from "moment";

function IncidencePanel(props) {
  useEffect(() => {
    props.onClickFn(activeTab, props.output.id);
  });
  const [activeTab, setActiveTab] = useState("Overview");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  let from=props.from;
  let refreshdata=props.refreshdata

  return (
    <>
      <div className="offcanvas-header p-0">
        <div className="bxSEDHead">
          <div className="bxOutliersCard">
            <div className="bxDelete" id="bxDeleteInsident">
                <button
                  className="bxDeletebtn"
                  data-bs-toggle="popover"
                  title="Delete"
                  onClick={() => {
                    Incidence.deleteIncidenceConfirmation(props.output.id,from,refreshdata);
                  }}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
            <h4 className="BXf-18 text-white pb-1 text-capitalize">
              <p className="me-3">
                {" "}
                <span
                  className={` badge  ${Incidence.badgecriticalityColor(
                    props.output.criticality
                  )}`}
                >
                  {props.output.criticality}
                </span>
              </p>
              <span className="rightPanel_Incident_Name" title={props.output.name}>
              {props.output.name}
              </span>
              
            </h4>
            <ul className="BXf-16 bxOC_list d-flex align-items-center text-capitalize mb-0 p-0 rightPanel_Incident_Desc" title={props.output.description}>
              {props.output.description}
            </ul>
            <ul className="bxOC_list d-flex align-items-center me-2 p-0 mb-0">
              <li className="d-flex align-items-center BXf-12 ">
                <span> {t( "createdon")}</span>
                <i className="ms-2 "> {moment(props.output.createdon).format("DD MMM hh:mm")} </i>
              </li>
              <li className="d-flex align-items-center BXf-12 ">
              <span> {t("last.updated.by")} :</span>
                <i className="ms-2 "> {props.output.updatedby} </i>
              </li>
              <li className="d-flex align-items-center BXf-12 ">
              <span>  {t("last.updated.on")} :</span>
                <i className="ms-2 "> {moment(props.output.updatedon).format("DD MMM hh:mm")} </i>
              </li>
              
            </ul>
          </div>
          {/* <button type="button" data-bs-dismiss="offcanvas" aria-label="Close"><span className="material-symbols-outlined">arrow_back</span></button> */}
        </div>
      </div>

      <div className="offcanvas-body p-0">
        <div className=" text-end float-end custom-mts">
          {activeTab === "events" ? (
            <div id="bnfxIncidenceEventsPagination"></div>
          ) : null}
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="Overview-tab"
              data-bs-toggle="tab"
              data-bs-target="#Overview-tab-pane"
              type="button"
              role="tab"
              aria-controls="bnfxIncidenceOverview"
              aria-selected="false"
              onClick={() => {
                handleTabClick("Overview");
              }}
            >
              {t("overview.tab")}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link "
              id="activities-tab"
              data-bs-toggle="tab"
              data-bs-target="#activities-tab-pane"
              type="button"
              role="tab"
              aria-controls="bnfxIncidenceActivities"
              aria-selected="false"
              onClick={() => {
                handleTabClick("Activities");
              }}
            >
              {t("activities.tab")}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="events-tab"
              data-bs-toggle="tab"
              data-bs-target="#events-tab-pane"
              type="button"
              role="tab"
              aria-controls="events-tab-pane"
              aria-selected="false"
              onClick={() => {
                handleTabClick("events");
              }}
            >
              {t("events.tab")}
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          {activeTab === "Overview" ? (
            <div id="bnfxIncidenceOverview"></div>
          ) : activeTab === "Activities" ? (
            <div id="bnfxIncidenceActivities"></div>
          ) : (
            <div id="bnfxIncidenceEvents"></div>
          )}
        </div>
      </div>
      <div className="offcanvas-footer bg_canvas p-2 text-end">
        <button
          type="button"
          className="me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {Incidence.removeClass();Incidence.removeOffcanvas()}}
        >
          {t("close")}
        </button>
      </div>
    </>
  );
}
export default IncidencePanel;
