import { t } from "i18next";
import Select from "react-select";
import { Organization } from "./OrganizationScript";
import { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { useGlobalState } from "../../components/common/GlobalVariables";

function AddNotification() {
  const [selectedOption, setSelectedOption] = useState(""); 
  const [appSubOption, setAppSubOption] = useState("user"); 

  const handleDestinationChange = (option) => {
    setSelectedOption(option);
    // Always default to "user" when destination is selected
    setAppSubOption("user");
  };

  const [selectedGroups, setSelectedGroups] = useGlobalState("selectedGroups");
  const [selectedUserTenants, setSelectedTenants] = useGlobalState("selectedUserTenants");

  const handleFormSubmit = (e) => {

    let selectedTenants=null
    if(selectedUserTenants?.[0]?.value===undefined){
      selectedTenants=  [{
        value: selectedUserTenants?.[0],
        label: selectedUserTenants?.[0],
    }]}
    else{
      selectedTenants=selectedUserTenants

    }

    const formData = {
        name: document.getElementById("notification_name").value,
        description: document.getElementById("notification_description").value,
        threshold: document.getElementById("threshold").value,
        Tenants: selectedTenants,
        // event:selectedEvent,
        // template:template,
        groups: selectedGroups,
      };

    Organization.addNotification();
    
  };

  return (
    <>
      <div
        className="bxOffcanvasAddOrg AddNotification offcanvas bg-dark offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddNotification"
        aria-labelledby="offcanvasAddNotificationLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title text-white"
            id="offcanvasAddTenantLabel"
          >
            Add Notification
          </h5>
        </div>
        <div className="offcanvas-body p-0">
          <div className="bxAddNotificationForm p-4">
            <form action="" id="notificationform">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="notification_name" className="form-label">
                      name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="notification_name"
                      placeholder="Enter"
                      autoComplete="off"
                      name="Name"
                      required
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="tenant" className="form-label">
                      Tenant
                    </label>
                    <Select
                      id="tenant"
                      aria-label="Default select example"
                      name="Tenant"
                      //   options={statusoptions}
                      //   value={selectedStatus}
                      //   onChange={handlestatusChange}
                    ></Select>
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="mb-2">
                    <label className="form-label">Event</label>
                    <Select
                      id="event_notification"
                      aria-label="Default select example"
                      name="event"
                      //   options={statusoptions}
                      //   value={selectedStatus}
                      //   onChange={handlestatusChange}
                    ></Select>
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="threshold" className="form-label">
                      Threshold
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="threshold"
                      placeholder="Enter"
                      autoComplete="off"
                      name="Threshold"
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>

                <div className="col">
                  <div className="mb-2">
                    <label
                      htmlFor="notification_description"
                      className="form-label"
                    >
                      {t("descripition")}
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="notification_description"
                      placeholder="Please enter description"
                      autoComplete="off"
                      maxLength="150"
                      rows="2"
                    />
                  </div>
                </div>
              </div>
            </form>

            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="notification_template" className="form-label">
                  Template
                </label>
                <div className="col-md-12 notification-code-editor">
                  <div className="notification-editor-header">
                    <h3 className="heading_name">
                      <span className="material-symbols-outlined">
                        data_object
                      </span>{" "}
                      Code
                    </h3>
                  </div>

                  <div
                    id="notificationeditor"
                    spellcheck="false"
                    className="notification-editor-layout"
                  >
                    <CodeMirror
                      className=""
                      id="bnfx_content_codemirror"
                      // value={nonStreamFields?.content || ""}
                      basicSetup={{
                        lineNumbers: true,
                        highlightActiveLineGutter: false,
                        highlightActiveLine: false,
                        lineWrapping: true,
                      }}
                      editable="true"
                      height="100%"
                      // style={{ tabSize: 10, fontSize: CODEMIRROR_FONT_SIZE }}
                      theme={"dark"}
                      extensions={EditorView.lineWrapping}
                      name="content"
                      // onChange={(value) => {
                      //   Content.onNoStreamItemChange(
                      //     { target: { name: "content", value: value } },
                      //     dispatch
                      //   );
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-2">
                <h6 className="sub_title">Destination</h6>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="radio-container">
                    <label htmlFor="bnfx_appButton" className="radio-label">
                      <input
                        type="radio"
                        name="custom-radio"
                        id="bnfx_appButton"
                        className="radio-input"
                        onChange={() => handleDestinationChange("app")}
                      />
                      <span className="radio-custom"></span>
                      App
                    </label>
                  </div>

                  <div className="radio-container">
                    <label htmlFor="bnfx_emailButton" className="radio-label">
                      <input
                        type="radio"
                        name="custom-radio"
                        id="bnfx_emailButton"
                        className="radio-input"
                        onChange={() => handleDestinationChange("email")}
                      />
                      <span className="radio-custom"></span>
                      Email
                    </label>
                  </div>

                  <div className="radio-container">
                    <label htmlFor="bnfx_smsButton" className="radio-label">
                      <input
                        type="radio"
                        name="custom-radio"
                        id="bnfx_smsButton"
                        className="radio-input"
                        onChange={() => handleDestinationChange("sms")}
                      />
                      <span className="radio-custom"></span>
                      SMS
                    </label>
                  </div>
                </div>
              </div>
              {/* Conditional Rendering */}
            </div>

            {(selectedOption === "app" || selectedOption === "email" ||selectedOption === "sms") && (
              <div className="col-sm-6">
                <div className="mb-2">
                  <h6 className="sub_title">Select one</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="radio-container">
                      <label htmlFor="bnfx_userButton" className="radio-label">
                        <input
                          type="radio"
                          name="app-radio"
                          id="bnfx_userButton"
                          className="radio-input"
                          onChange={() => setAppSubOption("user")}
                          checked={appSubOption === "user"}
                        />
                        <span className="radio-custom"></span>
                        User
                      </label>
                    </div>

                    <div className="radio-container">
                      <label htmlFor="bnfx_groupButton" className="radio-label">
                        <input
                          type="radio"
                          name="app-radio"
                          id="bnfx_groupButton"
                          className="radio-input"
                          onChange={() => setAppSubOption("group")}
                          checked={appSubOption === "group"}
                        />
                        <span className="radio-custom"></span>
                        Group
                      </label>
                    </div>
                  </div>
                </div>

                {appSubOption === "user" && (
                  <div className="col-sm-6">
                    <div className="mb-2">
                      <label htmlFor="user" className="form-label">
                        User
                      </label>
                      <Select
                        id="user"
                        aria-label="Default select example"
                        name="User"
                        // options={userOptions}
                        // value={selectedUser}
                        // onChange={handleUserChange}
                      ></Select>
                      <small className="d-block text-end bxFontSize">
                        {t("required")}
                      </small>
                    </div>
                  </div>
                )}

                {appSubOption === "group" && (
                  <div className="col-sm-6">
                    <div className="mb-2">
                      <label htmlFor="group" className="form-label">
                        Group
                      </label>
                      <Select
                        id="group"
                        aria-label="Default select example"
                        name="Group"
                        // options={groupOptions}
                        // value={selectedGroup}
                        // onChange={handleGroupChange}
                      ></Select>
                      <small className="d-block text-end bxFontSize">
                        {t("required")}
                      </small>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="offcanvas-footer py-2 px-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                Organization.removeClass();
              }}
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                handleFormSubmit();
              }}
            >
              {" "}
              {t("name.save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNotification;
