import DashboardFilterLayout from "../../pages/dashboard/details/DashboardFilterLayout";

import NetworkGraphModel from "../../pages/dashboard/details/NetworkGraphModel";
import AddDashboardLayout from "../../pages/dashboard/list/AddDashboardLayout";
import AddGroup from "../../pages/organization/AddGroup";
import AddRole from "../../pages/organization/AddRole";
import AddTenant from "../../pages/organization/AddTenant";
import AddUser from "../../pages/organization/AddUser";
import ActionLayout from "../../pages/stream/list/ActionLayout";
import AddStreamLayout from "../../pages/stream/list/AddStreamLayout";
import PanelLayout from "../../pages/stream/list/PanelLayout";
import IncidenceCreateContainer from "./IncidencePanel/IncidenceCreateContainer";
import IncidenceLayout from "./IncidencePanel/Incidencelayout";
import NewSearchLayout from "../../pages/search/NewSearchLayout";
import CommonModel from "./modal/CommonModel";
import SettingsModal from "./SettingsModal";
import ArtifactDetailsLayout from "./Artifacts/ArtifactDetailsLayout";
import AutomationLayout from "./Automations/AutomationLayout";
import SqlQueryModal from "./modal/SqlQueryModal";
import PlaybookExecutionLayout from "./Automations/PlaybookExecution/PlaybookExecutionLayout";
import AutomationTaskmap from "./Automations/AutomationTaskmap";
import BadgeList from "./Badge/BadgeList";
import CreateNewBadge from "./Badge/CreateNewBadge";
import CollectorsLayout from "../../pages/collectors/CollectorsLayout";
import DownloadModal from "./DownloadModal";
import DownloadsLayout from "../../pages/download/DownloadsLayout";
import CollectorInstructionLayout from "../../pages/collectors/CollectorInstructionLayout";
import AddNotification from "../../pages/organization/AddNotification";

function FooterInput() {
  return (
    <>
      <AddStreamLayout />
      <ActionLayout />
      <PanelLayout />
      <AddDashboardLayout />
      <AddRole />
      <AddUser />
      <AddGroup />
      <AddTenant />
      <AddNotification/>
      <DashboardFilterLayout />
      <NetworkGraphModel />
      <IncidenceLayout />
      <IncidenceCreateContainer />
      <NewSearchLayout />
      <CommonModel />
      <SettingsModal />
      <DownloadModal />
      <ArtifactDetailsLayout />
      <AutomationLayout />
      <SqlQueryModal />
      <PlaybookExecutionLayout />
      <AutomationTaskmap />
      {/* <CreateBadgeLayout/> */}
      <BadgeList />
      <CreateNewBadge />
      <CollectorsLayout />
      <DownloadsLayout />
      <CollectorInstructionLayout />
      <div id="modalname"></div>
    </>
  );
}

export default FooterInput;
