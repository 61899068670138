
export const LEFT_PANEL_SECTIONS = [
  {
    title: "Streams",
    name: "streams",
    icon: "database",
    components: [
      {
        title: "Parser",
        name: "parsers",
        icon: "hub"
      },
      {
        title: "Dictionary",
        name: "dictionaries",
        icon: "batch_prediction"
      },
      {
        title: "Filters",
        name: "filters",
        icon: "instant_mix"
      },
      {
        title: "Transformations",
        name: "transformations",
        icon: "move_down"
      },
      {
        title: "Correlation",
        name: "correlations",
        icon: "all_match"
      },
      {
        title: "Detections",
        name: "detections",
        icon: "detection_and_zone"
      },
      {
        title: "Sequences",
        name: "sequences",
        icon: "animation"
      },
      {
        title: "Automations",
        name: "automations",
        icon: "macro_auto"
      },
      {
        title: "Reports",
        name: "reports",
        icon: "lab_profile"
      },
      {
        title: "Fragments",
        name: "fragments",
        icon: "crossword"
      },
      {
        title: "Aggregations",
        name: "aggregations",
        icon: "join"
      }
    ]
  },
  {
    title: "Dashboards",
    name: "dashboards",
    icon: "dashboard",
    components: [
      {
        title: "Widgets",
        name: "widgets",
        icon: "widgets"
      },
      {
        title: "Widget Filter",
        name: "widgetfilters",
        icon: "filter_alt"
      }
    ]
  },
  {
    title: "Secrets",
    name: "secrets",
    icon: "visibility_lock",
    components: []
  },
  {
    title: "Variables",
    name: "variables",
    icon: "key",
    components: []
  },
  {
    title: "WorkFlows",
    name: "workflows",
    icon: "rebase",
    components: []
  }
];

export const RIGHT_PANEL_LABELS = {
  streams: {
    title: "Streams",
    description:
      "Create a set of packaged content to serve a use case , one stream package consists of datasource, filters, detections, automation.",
    createButtonText: "Create Stream",
    addTitle: "Add new stream",
    editTitle: "Edit stream",
    terminal: "stream"
  },
  parsers: {
    title: "Parsers",
    description:
      "Convert raw log data from various sources into a standardized JSON format to facilitate effective analysis, threat detection, and compliance management.",
    createButtonText: "Create Parser",
    addTitle: "Add new parser",
    editTitle: "Edit parser",
    terminal: "parser"
  },
  dictionaries: {
    title: "Dictionary",
    description:
      "This section is  used to convert data into Binaryflux schema.",
    createButtonText: "Create Dictionary",
    addTitle: "Add new dictionary",
    editTitle: "Edit dictionary",
    terminal: "dictionary"
  },
  filters: {
    title: "Filters",
    description:
      "In this section we, fetch and analyze the relevant data from a bulk of data logs coming from various sources by filtering based on specific criteria such as IP addresses, event types, or time frames etc.",
    createButtonText: "Create Filter",
    addTitle: "Add new filter",
    editTitle: "Edit filter",
    terminal: "filter"
  },
  transformations: {
    title: "Transformations",
    description:
      "The transformation function enriches log data by extracting additional information and modifying data fields to provide more context and insights.",
    createButtonText: "Create Transformation",
    addTitle: "Add new transformation",
    editTitle: "Edit transformation",
    terminal: "transformation"
  },
  correlations: {
    title: "Correlations",
    description:
      "The correlations function generates a visual graph to show the flow and relationships between events and their associated artifacts, highlighting their connections and interactions.",
    createButtonText: "Create Correlation",
    addTitle: "Add new correlation",
    editTitle: "Edit correlation",
    terminal: "correlation"
  },
  detections: {
    title: "Detections",
    description:
      "The detection function identifies and alerts on potential security threats based on criteria like time window, grouping, context, criticality, and MITRE techniques.",
    createButtonText: "Create Detection",
    addTitle: "Add new detection",
    editTitle: "Edit detection",
    terminal: "detection"
  },
  sequences: {
    title: "Sequences",
    description:
      "The sequence function analyzes log details to create a clear, chronological narrative of events during a security incident or detection.",
    createButtonText: "Create Sequence",
    addTitle: "Add new sequence",
    editTitle: "Edit sequence",
    terminal: "sequence"
  },
  automations: {
    title: "Automations",
    description:
      "The automation function automatically detects, investigates, and mitigates threats using programmatic solutions like playbooks, data collection, and coordinated responses.",
    createButtonText: "Create Automation",
    addTitle: "Add new automation",
    editTitle: "Edit automation",
    terminal: "automation"
  },
  reports: {
    title: "Reports",
    description:
      "This function configures and schedules email templates to send detection reports to authorized users at specified intervals, like daily or weekly.",
    createButtonText: "Create Report",
    addTitle: "Add new report",
    editTitle: "Edit report",
    terminal: "report"
  },
  fragments: {
    title: "Fragment",
    description:
      "This function creates individual components, like graphs, tables, or pie charts, that together form the complete report.",
    createButtonText: "Create Fragment",
    addTitle: "Add new fragment",
    editTitle: "Edit fragment",
    terminal: "fragment"
  },
  aggregations: {
    title: "Aggregations",
    description:
      "In this function, it used to generate the statistics of different artefacts.",
    createButtonText: "Create Aggregation",
    addTitle: "Add new aggregation",
    editTitle: "Edit aggregation",
    terminal: "aggregation"
  },
  workflows: {
    title: "Workflows",
    description:
      "In this function, it used to create a workflow structure.",
    createButtonText: "Create Workflow",
    addTitle: "Add new workflow",
    editTitle: "Edit workflow",
    terminal: "workflow"
  },
  dashboards: {
    title: "Dashboards",
    description:
      "Create a set of packaged content to serve a use case , one stream package consists of datasource, filters, detections, automation.",
    createButtonText: "Create Dashboard",
    addTitle: "",
    editTitle: "",
    terminal: "dashboard"
  },
  widgets: {
    title: "Widgets",
    description:
      "Create a set of packaged content to serve a use case , one stream package consists of datasource, filters, detections, automation.",
    createButtonText: "Create Widget",
    addTitle: "",
    editTitle: "",
    terminal: "widget"
  },
  widgetfilters: {
    title: "Widget Filters",
    description:
      "Create a set of packaged content to serve a use case , one stream package consists of datasource, filters, detections, automation.",
    createButtonText: "Create Filter",
    addTitle: "",
    editTitle: "",
    terminal: "widgetfilter"
  },
  secrets: {
    title: "Secrets",
    description: "Create a set of secrets.",
    createButtonText: "Create Secret",
    addTitle: "",
    editTitle: "",
    terminal: "secret"
  },
  variables: {
    title: "Variables",
    description: "Create a set of variables.",
    createButtonText: "Create Variable",
    addTitle: "",
    editTitle: "",
    terminal: "variable"
  }
};

export const STREAMS_DROPDOWN_OPTIONS = [
  {
    title: "Parser",
    name: "parsers",
    icon: "hub"
  },
  {
    title: "Dictionary",
    name: "dictionaries",
    icon: "batch_prediction"
  },
  {
    title: "Filters",
    name: "filters",
    icon: "instant_mix"
  },
  {
    title: "Transformations",
    name: "transformations",
    icon: "move_down"
  },
  {
    title: "Correlation",
    name: "correlations",
    icon: "all_match"
  },
  {
    title: "Detections",
    name: "detections",
    icon: "detection_and_zone"
  },
  {
    title: "Sequences",
    name: "sequences",
    icon: "animation"
  },
  {
    title: "Automations",
    name: "automations",
    icon: "macro_auto"
  },
  {
    title: "Reports",
    name: "reports",
    icon: "lab_profile"
  },
  {
    title: "Aggregations",
    name: "aggregations",
    icon: "join"
  }
];

export const SCHEDULE_REPORTS = [
  {
    label: "SEMIANNUAL",
    value: "@semiannual"
  },
  {
    label: "QUARTERLY",
    value: "@quarterly"
  },
  {
    label: "ANUALLY",
    value: "@annually"
  },
  {
    label: "MONTHLY",
    value: "@monthly"
  },
  {
    label: "WEEKLY",
    value: "@weekly"
  },
  {
    label: "DAILY",
    value: "@daily"
  }
];


export const DATA_TYPES = [
  {
    label: "STRING",
    value: "string"
  },
  {
    label: "NUMBER",
    value: "number"
  },
  {
    label: "DECIMAL",
    value: "decimal"
  },
  {
    label: "LIST",
    value: "list"
  },
  {
    label: "MAP",
    value: "map"
  },
  
];

export const INPUT_FIELDS_BY_TYPE = {
  reports: [
    {
      type: "select",
      name: "schedule",
      label: "Schedule",
      options: SCHEDULE_REPORTS
    },
    {
      type: "multiselect",
      name: "fragments",
      label: "Fragments"
    }
  ],
  dashboards: [
    {
      type: "multiselect",
      name: "widgets",
      label: "Widgets"
    }
  ]
};

export const DATA_EXTRACTION_FROM_CODE = {
  report: {
    extract: [
      {
        extractionName: "schedule",
        fieldToUpdateName: "schedule"
      }
    ],
    remove: ["schedule", "fragments"],
    loadData: [
      {
        apiName: "report",
        apiFieldName: "fragments",
        fieldToUpdateName: "fragments"
      }
    ]
  },
  dashboard: {
    extract: [
      {
        extractionName: "widgets",
        fieldToUpdateName: "widgets"
      }
    ],
    loadData: [
      {
        apiName: "dashboard",
        apiFieldName: "widgets",
        fieldToUpdateName: "widgets"
      }
    ]
  }
};

export const HIDE_CODE_BLOCK_FOR_TYPE = ["dashboards", "secrets", "variables"];
export const HIDE_GENERATE_AND_TEST_FOR_TYPE = [
  "dashboards",
  "widgets",
  "widgetfilters",
  "secrets",
  "variables",
  "workflows"
];
export const FORM_FOR_NON_PIPELINE_TERMINAL_NAME = ["secret", "variable"];

export const ADD_YOUR_CODE = "#Add your code here";
export const ADD_YOUR_TEST_EVENT = "#Add the event here to test it";
export const CODEMIRROR_FONT_SIZE = "14px";
