import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock'; // Use highstock for stock charts

const StockTrendChart = (props) => {
const chartContainer = useRef(null);
  const chartRef = useRef(null); // Store the chart instance

  const { container, data ,onChartReady} = props;
  
  useLayoutEffect(() => {
    let height = props?.height;
    if (chartContainer.current) {
      chartRef.current =Highcharts.stockChart(container, {
        chart: {
          marginTop: 0, 
          height: height + 'px' ,
          marginBottom: 25,// Remove padding from the bottom // Fallback to 400px if height is not found
        },
        title: {
          text: '',
        },
        subtitle: {
          text: 'Last 30 Minutes',
          style: {
            fontSize: '7px', // Set a smaller font size if needed
            marginBottom: '5px', // Adjust the spacing between the subtitle and the chart
          },
          verticalAlign: 'top', // Position the subtitle at the top
          y: 0, // Offset the subtitle from the top of the chart
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: '' // Remove x-axis title
          },
          labels: {
            enabled:false
          },
          gridLineWidth: 0,
          offset: 20,
          events: {
            afterSetExtremes: (event) => { 
              if (event.trigger === 'navigator' || event.trigger === 'zoom') {
                if (props.onRangeChange) {
                  props.onRangeChange(event.min, event.max);
                }
              }
            }
          }
        },
        yAxis: {
          title: {
            text: '' // Remove y-axis title
          },
          opposite: false, // Position the Y-axis on the left
          labels: {
            enabled:false
          },
          gridLineWidth: 0,
        },
        rangeSelector: {
          enabled: false, // Disable the range selector buttons
        },
        navigator: {
          height: 10, // Reduce the height of the navigator
          margintop: 5, // Adjust margin to make it more compact
          handles: {
            width: 5, // Compact handle size
            height: 10, // Compact handle height
          },
          outlineWidth: 0, // Remove navigator outline for a cleaner look
          maskFill: 'rgba(102, 133, 194, 0.2)', // Navigator fill color with some transparency
          series: {
            color: '#23483b', // Navigator series color
            lineWidth: 1, // Reduce the line width for a compact look
          },
          xAxis: {
            labels: {
              enabled: false, // Disable the labels on the navigator's x-axis
            },
          },
        },
        scrollbar: {
          enabled: false, // Ensure the scrollbar is fully disabled
        },
        series: [
          {
            name: 'AAPL',
            type: 'areaspline',
            data: data, // Use the zigzag dummy data
            gapSize: 5,
            color: '#23483b', 
            lineWidth:1.5,
            tooltip: {
              valueDecimals: 2,
            },
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, 'rgba(35, 72, 59, 0.4)'], // Custom color #23483b at the top (40% opacity)
                [1, 'rgba(35, 72, 59, 0)'],   // Fade to transparent at the bottom
            ],
            
            },
            threshold: null,
          },
        ],
        credits: {
          enabled: false,
        },
      });
      if (onChartReady) {
        onChartReady(chartRef.current);
      }
    }
  }, [container,data]);
  const updateChartData = (newData) => {
    if (chartRef.current) {
      newData.forEach(point => {
        chartRef.current.series[0].addPoint(point, true, false); // Add new point without redrawing
      });
    }
  };

  useEffect(() => {
    if (props.onUpdateData) {
      props.onUpdateData(updateChartData);
    }
  }, [updateChartData]);

  return <div ref={chartContainer} id={container} />;
};

export default StockTrendChart;