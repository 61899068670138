import { t } from "i18next";
import Search from "../../components/search/Search";
import { Organization } from "./OrganizationScript";
import { useLayoutEffect } from "react";

function OrganizationGroupLayout() {
  useLayoutEffect(() => {

    const groupsearchbar = document.getElementById("group_search")
    if (groupsearchbar) {
      groupsearchbar.addEventListener("keydown", function () {
        Organization.search("group_search");
      });
      groupsearchbar.addEventListener("input", function () {
        if (groupsearchbar.value === "") {
          document.getElementById("group_search").value = null;
          Organization.loadGroup(0);
        }
      });
    }

    const rolesearchbar = document.getElementById("role_search")
    if (rolesearchbar) {
      rolesearchbar.addEventListener("keydown", function () {
        Organization.search("role_search");
      });
      rolesearchbar.addEventListener("input", function () {
        if (rolesearchbar.value === "") {
          document.getElementById("role_search").value = null;
          Organization.loadRole(0);
        }
      });
    }

    const usersearchbar = document.getElementById("user_search")
    if (usersearchbar) {
      usersearchbar.addEventListener("keydown", function () {
        Organization.search("user_search");
      });
      usersearchbar.addEventListener("input", function () {
        if (usersearchbar.value === "") {
          document.getElementById("user_search").value = null;
          Organization.loadUser(0);
        }
      });
    }

    const tenantsearchbar = document.getElementById("tenant_search")
    if (tenantsearchbar) {
      tenantsearchbar.addEventListener("keydown", function () {
        Organization.search("tenant_search");
      });
      tenantsearchbar.addEventListener("input", function () {
        if (tenantsearchbar.value === "") {
          document.getElementById("tenant_search").value = null;
          Organization.loadTenant(0);
        }
      });
    }

  });
  return (
    <>
    <div
        className="tab-pane fade"
        id="bxNotification-tab-pane"
        role="tabpanel"
        aria-labelledby="bxNotification-tab"
        tabIndex="0"
      >
        <button
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasAddNotification"
          aria-controls="offcanvasAddNotification"
          href="#"
          className="addNewBtn action-icon"
          onClick={() => Organization.showClass()}
        >
          <span className="material-symbols-outlined">
          person_add
          </span>
          Add Notification
        </button>
        <div className="col-12">
          <div className="leftNavResult d-flex justify-content-between align-items-center py-2 px-4">
            <div className="leftNavResultCount d-flex justify-content-between align-items-center">
              <form className="sidebar-search">
                <Search
                  id="tenant_search"
                  defaultValue=""
                />
              </form>

              <div className="form-check ms-3">
                <label className="form-check-label" htmlFor="Results">
                  {" "}
                  <span id="totaltenant"></span>
                  <span> {t("results")}</span>{" "}
                </label>
              </div>
            </div>
            <div
              className="rightNavAction d-flex align-items-center"
              id="paginationtenant"
            ></div>
          </div>
        </div>
        <div id="addnotification"></div>
      </div>
      
      <div
        className="tab-pane fade"
        id="bxTenants-tab-pane"
        role="tabpanel"
        aria-labelledby="bxTenants-tab"
        tabIndex="0"
      >
        <button
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasAddTenant"
          aria-controls="offcanvasAddTenant"
          href="#"
          className="addNewBtn action-icon"
          onClick={() => Organization.showClass()}
        >
          <span className="material-symbols-outlined">
          person_add
          </span>
          {t("organization.add.new.tenant.text")}{" "}
        </button>
        <div className="col-12">
          <div className="leftNavResult d-flex justify-content-between align-items-center py-2 px-4">
            <div className="leftNavResultCount d-flex justify-content-between align-items-center">
              <form className="sidebar-search">
                <Search
                  id="tenant_search"
                  defaultValue=""
                />
              </form>

              <div className="form-check ms-3">
                <label className="form-check-label" htmlFor="Results">
                  {" "}
                  <span id="totaltenant"></span>
                  <span> {t("results")}</span>{" "}
                </label>
              </div>
            </div>
            <div
              className="rightNavAction d-flex align-items-center"
              id="paginationtenant"
            ></div>
          </div>
        </div>
        <div id="addtenant"></div>
      </div>
      <div
        className="tab-pane fade "
        id="bxGroups-tab-pane"
        role="tabpanel"
        aria-labelledby="bxGroups-tab"
        tabIndex="0"
      >
        <button
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasAddGroup"
          aria-controls="offcanvasAddGroup"
          href="#"
          className="addNewBtn action-icon"
          onClick={() => {Organization.loadgroupselectionoptions(); Organization.showClass();}}
        >
          <span className="material-symbols-outlined">
            {" "}
            person_add
          </span>
          {t("organization.add.new.group.text")}{" "}
        </button>
        <div className="col-12">
          <div className="leftNavResult d-flex justify-content-between align-items-center py-2 px-4">
            <div className="leftNavResultCount d-flex justify-content-between align-items-center">
              <form className="sidebar-search">
                <Search
                  id="group_search"
                  defaultValue=""
                />
              </form>
              <div className="form-check ms-3">
                <label className="form-check-label" htmlFor="Results">
                  {" "}
                  <span id="totalsecuritygroup"></span>
                  <span> {t("results")}</span>{" "}
                </label>
              </div>
              
            </div>
            <div
              className="rightNavAction d-flex align-items-center"
              id="paginationsecuritygroup"
            ></div>
          </div>
        </div>
        <div id="addgroup"></div>
      </div>
      <div
        className="tab-pane fade"
        id="bxRoles-tab-pane"
        role="tabpanel"
        aria-labelledby="bxRoles-tab"
        tabIndex="0"
      >
        <button
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasAddRole"
          aria-controls="offcanvasAddRole"
          href="#"
          className="addNewBtn action-icon"
          onClick={() => {Organization.loadroleselectionoptions(); Organization.showClass();}}
        >
          <span className="material-symbols-outlined">
          person_add
          </span>
          {t("organization.add.new.role.text")}{" "}
        </button>
        <div className="col-12">
          <div className="leftNavResult d-flex justify-content-between align-items-center py-2 px-4">
            <div className="leftNavResultCount d-flex justify-content-between align-items-center">
              <form className="sidebar-search">
                <Search
                  id="role_search"
                  defaultValue=""
                />
              </form>
              <div className="form-check ms-3">
                <label className="form-check-label" htmlFor="Results">
                  {" "}
                  <span id="totalsecurityrole"></span>
                  <span> {t("results")}</span>{" "}
                </label>
              </div>
            </div>
            <div
              className="rightNavAction d-flex align-items-center"
              id="paginationsecurityrole"
            ></div>
          </div>
        </div>
        <div id="addrole"></div>
      </div>
      <div
        className="tab-pane fade show active"
        id="bxUsers-tab-pane"
        role="tabpanel"
        aria-labelledby="bxUsers-tab"
        tabIndex="0"
      >
        <button
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasAddUser"
          aria-controls="offcanvasAddUser"
          href="#"
          className="addNewBtn action-icon"
          onClick={() => {Organization.loaduserselectionoptions(); Organization.showClass();}}
        >
          <span className="material-symbols-outlined">
          person_add
          </span>
          {t("organization.add.new.user.text")}{" "}
        </button>
        <div className="col-12">
          <div className="leftNavResult d-flex justify-content-between align-items-center py-2 px-4">
            <div className="leftNavResultCount d-flex justify-content-between align-items-center">
              <form className="sidebar-search">
                <Search
                  id="user_search"
                  defaultValue=""
                />
              </form>

              <div className="form-check ms-3">
                <label className="form-check-label" htmlFor="Results">
                  {" "}
                  <span id="totalsecurityuser"></span>
                  <span> {t("results")}</span>{" "}
                </label>
              </div>
            </div>
            <div
              className="rightNavAction d-flex align-items-center"
              id="paginationsecurityuser"
            ></div>
          </div>
        </div>
        <div id="adduser"></div>
      </div>
    </>
  );
}

export default OrganizationGroupLayout;
