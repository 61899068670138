
function ListItem({ data, selectedType, terminalName, onCreateOrEditClick }) {

  function generateRandomColor() {
    const getRandomLightValue = () => Math.floor(Math.random() * 156) + 100;// Darker shades with values from 0 to 50

    const r = getRandomLightValue();
    const g = getRandomLightValue();
    const b = getRandomLightValue();

    return `rgb(${r}, ${g}, ${b})`;
  }
    const randomColor = generateRandomColor();
    const buttonStyle = {
    color: randomColor,
    };
  return (
    <li className="col-md-4">
      <div className="bxStreamEDHead">
        <a
          onClick={() => onCreateOrEditClick(data, terminalName)}
          className="d-flex align-items-center"
        >
           
            {/* <span class="bxStreamIcon_link bxIcon_link slack  d-block"></span> */}
            <span
                  className={
                    "bxIcon_link " + data?.datasource?.toLowerCase()
                  }
                  style={buttonStyle}
                >
                  {data.source?data.source?.charAt(0).toUpperCase():data?.title?.charAt(0).toUpperCase()}
                </span>
          
          <div className="bxStream_linkContent">
            <h3 className="bxStreamTitle_link text-white">{data.secret?data.secret:data.title}</h3>
            <p className="bxStreamDiscription_link">{data.source?data.source:data.description}</p>
          </div>
        </a>
      </div>
      {/* <button class="btn btn-dark text-white">PUBLISH</button> */}
    </li>
  );
}

export default ListItem;