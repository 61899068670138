import { t } from "i18next";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../components/common/GlobalVariables";
import { Stream } from "../StreamScript";
import { Artifact } from "../../../components/common/Artifacts/ArtifactScript";


function Metadata({ stream }) {
  const [selectedDays, setSelectedDays] = useState(Stream.selected_days);
  const [isTabActive, setIsTabActive] = useState(false);
  // const [showToggle] = useGlobalState("showToggle");

  const handleDaysSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    Stream.selected_days = selectedValue;
    setSelectedDays(Stream.selected_days);
    Stream.detectionSql = null;
    // Artifact.getArtifactList(stream?._id);
    Stream.getStats(stream);
    Stream.detectionSearchSuggest(Stream.selected_stream?._id);
    Artifact.getArtifactList(Stream.selected_stream?._id);
    Stream.getDetections(Stream.selected_stream?._id);
  };
  let correlationcount = stream?.correlation?.count;
  if (correlationcount === undefined) {
    correlationcount = 0;
  }
  let detectionscount = stream?.detections?.count;
  if (detectionscount === undefined) {
    detectionscount = 0;
  }
  // const handleToggleButton = () => {
  //   setGlobalState("showToggle", !showToggle);
  // };

  let rawEventsTabIsActive=useGlobalState("rawEventsTabIsActive")
  useEffect(() => {
    const element = document.querySelector('.tab_rawevents');
    if (element) {
      setIsTabActive(element.classList.contains('active'));
    }
  }, [rawEventsTabIsActive]); 

  return (
    <>
      <div className="row">
        <div className="col-12 ToggleButton">
          <div className="d-flex align-items-center justify-content-between">
            <div className="bxStreamEDHead d-flex align-items-center">
              <span
                className={"bxIcon_link " + stream.datasource.toLowerCase()}
              >
                {stream.title.charAt(0)}
              </span>
              <div className="bxStream_linkContent">
                <h3 className="bxStreamTitle_link text-white">
                  {stream?.title}
                </h3>
                <p className="bxStreamDiscription_link">
                  {stream?.description}
                </p>
              </div>
            </div>

            {/* <label htmlFor="togBtn" className="switch streamswitch">
            <input
              type="checkbox"
              id="togBtn"
              // onChange={() => setshowToggle(!showToggle)}
              onChange={handleToggleButton}
            />

            <div className="slider round">
              <span className="on">ACROSS</span>
              <span className="off">SPECIFIC</span>
            </div>
          </label> */}

            <ul className="d-flex bxSEActionBtn m-0 p-0">
              {correlationcount > 0 && detectionscount === 0 ? null : (
                <li className="me-1" id="numberofdaysofstream">
                  <span className="material-symbols-outlined">
                    hourglass_top
                  </span>
                  <select
                    className="daysdropdown"
                    value={selectedDays}
                    onChange={handleDaysSelectChange}
                    disabled={isTabActive}
                  >
                    <option value="1">{t("days.today")}</option>
                    <option value="3">{t("days.last 3 days")}</option>
                    <option value="5">{t("days.last 5 days")}</option>
                    <option value="7">{t("days.last 7 days")}</option>
                    <option value="15">{t("days.last 15 days")}</option>
                    <option value="30">{t("days.last 30 days")}</option>
                    <option value="45">{t("days.last 45 days")}</option>
                    <option value="60">{t("days.last 60 days")}</option>
                    <option value="90">{t("days.last 90 days")}</option>
                    <option value="120">{t("days.last 120 days")}</option>
                    <option value="150">{t("days.last 150 days")}</option>
                    <option value="180">{t("days.last 180 days")}</option>
                  </select>
                </li>
              )}
                 
              <li className="ms-1 bxDelete" id="bxDeleteStream">
                <button
                  className="bxReloadBtn"
                  data-bs-toggle="popover"
                  title="Delete"
                  onClick={() => {
                    Stream.deleteConfirmation(stream.title);
                  }}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Metadata;
