import React from "react";
import ListItem from "./ListItem"; // Assuming you have a StreamItem component
import LoadingWave from "../../../components/common/loadingwaveicon/LoadingWave";
import NoContent from "../../../components/common/NoContent";

function RightPanelContentItemsList({
  originalData,
  type,
  terminalName,
  onCreateOrEditClick
}) {
  // Calculate the number of groups needed (each group contains 3 items)
  const groupSize = 3;
  const groupedData = [];
  for (let i = 0; i < originalData?.length; i += groupSize) {
    groupedData.push(originalData.slice(i, i + groupSize));
  }

  return (
    <div id="" className="bxSettingPage_listBox">
      <div className="list grid-view-filter">
        {originalData?.length === 0 && <NoContent />}
        {groupedData.length === 0 && originalData?.length !== 0 && (
          <LoadingWave />
        )}
        {groupedData.map((group, index) => (
          <div key={index} className="list_area">
            <div className="row">
              {group.map((item, idx) => (
                <ListItem
                  key={item.id || idx}
                  data={item}
                  selectedType={type}
                  terminalName={terminalName}
                  onCreateOrEditClick={onCreateOrEditClick}
                /> // Assuming StreamItem takes a 'data' prop
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RightPanelContentItemsList;
