import React from "react";

function AutomationSummaryModal({ show, onClose, bodyContent,header }) {

  if (!show) return null;

  return (
    <>
      {/* Backdrop Layer */}
      <div 
        className="modal-backdrop fade show" 
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.9)", // Semi-transparent black
          zIndex: 1040 // Make sure the backdrop is behind the modal
        }}
      ></div>

      {/* Modal */}
      <div
        className="AutomationSummaryModal modal fade show"
        style={{
          display: "block",
          zIndex: 1050 // Ensure the modal appears above the backdrop
        }}
        tabIndex="-1"
      >
        <div className="AutomationSummaryModal-dialog modal-dialog-scrollable bxPopup">
          <div className="AutomationSummaryModal-content modal-content">
            <div className="AutomationSummaryModal-header modal-header">
            <h5 class="modal-title" >{header}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="AutomationSummaryModal-body modal-body">
              {bodyContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutomationSummaryModal;