import React from "react";
import moment from "moment";
import { Stream } from "../StreamScript";
import { flatten } from "flat";

function DatabaseEventLayout(props) {
  let output = props.data;
  let detectionData = output;

  const { streamid, id,timestamp, ...requireDetectionData } = detectionData;
  const processedOutputData = flatten(requireDetectionData);

  const outputarrafterfive = [];
  const outputarrbeforefive = [];

  const filteredData = Object.entries(processedOutputData).filter(
    ([key, value]) => value !== null && value !== "" && value !== undefined
  );

  for (let index = 0; index < filteredData.length; index++) {
    const [key, value] = filteredData[index];

    if (index >= 5) {
      outputarrafterfive.push([key, value]);
    } else {
      outputarrbeforefive.push([key, value]);
    }
  }


  
  const dateTime = moment(Number(detectionData.timestamp)).format('DD MMM hh:mm:ss');

  return (
    <div className="bnfxDESDetails pb-0 databaseEvent">
      <div className="bnfxDectionsEvents mt-0">
        <div className="row">
          <div className="col-12">
            <div className="row bxStreamEventDetection m-0 mb-1 d-flex align-items-center">
              <div className="bxSEDBody col-md-12">
                <p className="dateTime mt-1 mb-0">{dateTime}</p>
                <div className="bxSEDBodyBox_inner">
                  <div className="tags_foot mt-1 mb-2">
                    {outputarrbeforefive?.map((attr) => (
                      <React.Fragment key={attr[0]}>
                        <span
                          className="bxEventKey"
                          onDoubleClick={() =>
                            Stream.handleDoubleClick(attr[0], attr[1])
                          }
                        >
                          {attr[0]}
                        </span>
                        <span
                          className="bxEventValue"
                          onDoubleClick={() =>
                            Stream.handleDoubleClick(attr[0], attr[1])
                          }
                        >
                          {attr[1]}
                        </span>
                      </React.Fragment>
                    ))}
                  </div>
                  {filteredData.length > 5 && (
                  <div
                    className="ExpandCollapseBtn"
                    onClick={(e) => Stream.loadmoredatacoallapse(e.target)}
                    data-bs-toggle="collapse"
                    data-bs-target={
                      "#databasecollapsebxJsonText" + props.index
                    }
                    aria-expanded="false"
                    aria-controls="collapsebxJsonText"
                  >
                    <span className="material-symbols-outlined text-success">
                      expand_more
                    </span>
                  </div>
                  )}
                </div>
                <div
                  className="bxJsonText collapse"
                  id={"databasecollapsebxJsonText" + props.index}
                >
                  {outputarrafterfive?.map((attr) => (
                    <React.Fragment key={attr[0]}>
                        <span
                          className="bxEventKey"
                          onDoubleClick={() =>
                            Stream.handleDoubleClick(attr[0], attr[1])
                          }
                        >
                          {attr[0]}
                        </span>
                        <span
                          className="bxEventValue"
                          onDoubleClick={() =>
                            Stream.handleDoubleClick(attr[0], attr[1])
                          }
                        >
                          {attr[1]}
                        </span>
                      </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatabaseEventLayout;
