import { t } from "i18next";
import Select from "react-select";
import {
  getGlobalState,
  useGlobalState,
} from "../../components/common/GlobalVariables";
import { SearchTemplate } from "./SearchScript";
import { Stream } from "../stream/StreamScript";

function SaveSearchLayout() {
  const [isSearchEdit] = useGlobalState("isSearchEdit");
  const [searchText] = useGlobalState("global_search_value");
  const [editQuery] = useGlobalState("global_edit_query");
  const [selectedSchedule] = useGlobalState("selected_schedule");
  let finalfreetext = null;
  if (Stream.selected_freetext === null) {
    finalfreetext = "select * from detection";
  } else {
    finalfreetext = "select * from detection where " + Stream.selected_freetext;
  }

  //   const handleInputChange = () => {
  //     let areAllFieldsFilled = true;

  //     const titlefield = document.getElementById("bnfx_globalsearchtitle");
  //     if (!titlefield || titlefield.value?.trim() === "") {
  //       titlefield?.classList.add("border-danger");
  //       areAllFieldsFilled = false;
  //     } else {
  //       titlefield?.classList.remove("border-danger");
  //     }

  //   const tenantfield = document.getElementById("bnfx_globaltenants");

  //   if (!selectedSearchTenants) {
  //     tenantfield?.classList.add("border-danger");
  //     areAllFieldsFilled = false;
  //   } else {
  //     tenantfield?.classList.remove("border-danger");
  //   }
  //   setIsSearchSaveEnabled(areAllFieldsFilled);
  // };

  const handleFormSubmit = () => {
    const formData = {
      title: document.getElementById("bnfx_globalsearchtitle").value,
      description: document.getElementById("bnfx_globalsearchdescription")
        .value,
    };
    if (isSearchEdit) {
      SearchTemplate.updateSearch(formData, editQuery);
    } else {
      let pathname = window.location.pathname;
      if (pathname === "/searchpage") {
        SearchTemplate.createSearch(formData, searchText);
      } else {
        SearchTemplate.createSearch(formData, finalfreetext);
      }
      
    }
  };

  let singletenant = getGlobalState("singletenant");
  let tenantoption = null;
  const tenantListDiv = document.getElementById("bnfx_tenantlists");
  const hasContent = tenantListDiv && tenantListDiv.children.length > 0;
  if (singletenant) {
    tenantoption = null;
  } else {
    tenantoption = (
      <>
     {hasContent && <label htmlFor="tenant">{t("name.tenant")}</label>}
        <div className="mb-3" id="bnfx_tenantlists">
          
        </div>
      </>
    );
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title pe-3" id="newSearchLabel">
            {editQuery ? editQuery : searchText}
          </h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <form action="">
            <div className="mb-3">
              <label htmlFor="bnfx_globalsearchtitle">{t("name.title")}</label>
              <input
                type="text"
                className="form-control"
                id="bnfx_globalsearchtitle"
                placeholder="Enter Title"
                autoComplete="off"
                name="title"
                required
                //onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="bnfx_globalsearchdescription">{t("name.description")}</label>
              <textarea
                className="form-control"
                id="bnfx_globalsearchdescription"
                placeholder="Enter Description"
                autoComplete="off"
                name="description"
              ></textarea>
            </div>
            <div className="mb-3">
              <label >{t("schedule_title")}</label>
              <Select
                id="scheduletype"
                className="form-control"
                name="schedule"
                options={SearchTemplate.scheduleoptions}
                onChange={SearchTemplate.handleScheduleChange}
                value={selectedSchedule && selectedSchedule.value ? selectedSchedule : { value: '', label: '' }}  // Ensure valid object
                // defaultValue={result ? risk : CommonTemplate.scheduleoptions[0]}
              ></Select>
            </div>
            {tenantoption}
         
            
  
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
            onClick={handleFormSubmit}
            //disabled={!isSearchSaveEnabled}
          >
            {t("name.save")}
          </button>
        </div>
      </div>
    </>
  );
}

export default SaveSearchLayout;
