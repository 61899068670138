import { useEffect } from "react";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useLocation } from "react-router";
import "../content/content.css";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import LayoutPanel from "./contentpanels/LayoutPanel";
import { Content } from "./ContentScript";

function ContentTemplate() {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code'); 

    Navigation.onnavigate(location.pathname + location.search);
    CommonTemplate.removeSaveSearchModal();

    const newUrl=window.location.origin+location.pathname;
    window.history.replaceState(null, "", newUrl);

    if(code){
      Content.generateGoogleAuth(code);
    }
  }, [location.pathname,location.search]);

  return (
    <div>
      <div id="wrapper" className="bxContent contentEditPage">
        <LayoutPanel />
      </div>
    </div>
  );
}

export default ContentTemplate;