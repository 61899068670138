import { ajax, sessionexpired } from "../../components/common/Service";
import { requestURI } from "../../components/common/RequestURIs";
import { setGlobalState } from "../../components/common/GlobalVariables";
import ToastPopup from "../../components/common/ToastPopup";
import { t } from "i18next";
import { DisplayErrorToast } from "../../components/error/DisplayErrorToast";


export const Login = {
  valuemap: {},

  updateOnChange: (event) => {
    let value = event.target.value;
    if (value === "") {
      Login.adderror(event.target);
      delete Login.valuemap[event.target.id];
    } else {
      Login.valuemap[event.target.id] = value;
      event.target.classList.remove("border-danger");
      event.target.parentElement
        .querySelector(".bxErrorMessage")
        .classList.add("d-none");
    }
  },

  adderror: (element) => {
    if (element && element.parentElement) {
      element.classList.add("border-danger");
      const errorMessage =
        element.parentElement.querySelector(".bxErrorMessage");

      if (errorMessage) {
        errorMessage.classList.remove("d-none");
      }
    }
  },


  loginCallback: (result, args) => {
  if (result.data.response.mfareqvalidator) {
      const accountDetails = {
        accountid: args[1],
        username: args[2]
      };
      args[0]({...result.data.response, ...accountDetails});
    } else {
      localStorage.setItem("token", result.data.response.token);
      localStorage.setItem("lastloggingtime", result.data.starttime);
      localStorage.setItem("loggedIn", true);
      ToastPopup(t("login.message"));
      localStorage.setItem("loggedInUser", result.data.response.name);
      setGlobalState("loggedIn", true);
      window.location.reload();
    }
  },

  removeToken: () => {
    localStorage.removeItem("token");
  },

  logoutCallback: () => {
    localStorage.setItem("loggedIn", false);
    Login.removeToken();
    localStorage.setItem("loggedInUser", null);

    setGlobalState("loggedIn", false);
    window.location.reload();
  },

  validate: () => {
    let isError = false;
    if (
      document.getElementsByClassName("border-danger").length > 0 ||
      Object.keys(Login.valuemap).length < 3
    ) {
      if (Object.keys(Login.valuemap).length < 3) {
        let elements = document.querySelectorAll(".form-control");
        elements.forEach((element) => {
          if (!Login.valuemap[element.id]) {
            Login.adderror(element);
            isError = true;
          }
        });
      }
    }
    return !isError;
  },
  validateOtp: () => {
    let isError = false;
    if (
      document.getElementsByClassName("border-danger").length > 0 ||
      Login.valuemap.mfa.length < 4
    ) {
      if (Login.valuemap.mfa.length < 3) {
        let element = document.getElementById("mfa");
        Login.adderror(element);
        isError = true;
      }
    }
    return !isError;
  },
  authenticate: (setMfaDataFn) => {
    ajax(
      "post",
      requestURI["login.auth"],
      null,
      {
        accountid: Login.valuemap["accountid"],
        username: Login.valuemap["username"],
        password: Login.valuemap["password"],
      },
      Login.loginCallback,
      DisplayErrorToast,
      [setMfaDataFn, Login.valuemap["accountid"], Login.valuemap["username"]]
    );
  },
  authenticateOtp: (mfaData) => {
    ajax(
      "post",
      requestURI["login.auth.mfa"],
      null,
      {
        "username": mfaData.username,
        "accountid": mfaData.accountid,
        "mfatype": mfaData.mfatype,
        "mfareqvalidator": mfaData.mfareqvalidator,
        "mfacode": Login.valuemap.mfa
      },
      Login.loginCallback,
      DisplayErrorToast
    );
  },
  logout: () => {
    ajax(
      "get",
      requestURI["logout"],
      null,
      null,
      Login.logoutCallback,
      DisplayErrorToast
    );
  },
  refreshtoken: async (token) => {
    const headers = {
      Authorization: {token},
    };

    const result = await ajax(
      "post",
      requestURI["refresh.auth.token"],
      headers, 
      null, 
      null,
      sessionexpired,
        null,
         true
    );

    Login.renderrefreshtoken(result)

  },
  renderrefreshtoken: (result) => {
    setGlobalState("isexpirycheck",false)
    if (result && result.data && result.data.response && result.data.response.token) {
      localStorage.setItem("token", result.data.response.token);
    } else {
      sessionexpired(result)
    }


  },

  clearData: () => {
    const inputElements = document.querySelectorAll(".login");
    inputElements.forEach((element) => {
      element.value = "";
    });
    Login.valuemap = {};
  },

  signIn: (event, setMfaDataFn) => {
    event.preventDefault();
    if (Login.validate()) {
      Login.authenticate(setMfaDataFn);
      Login.clearData();
    }
  },
  submitOtp: (event, mfaData) => {
    event.preventDefault();
    if (Login.validateOtp()) {
      Login.authenticateOtp(mfaData);
      Login.clearData();
    }
  }
};
