import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import IncidenceActivitesChat from "./IncidenceActivitiesChat";
import { Incidence } from "./IncidenceScript";
import AutomationSummaryModal from "./AutomationSummaryModal";

function IncidenceActivites(props) {
  let result = props.data;
  let newoffset = null;
  const editorRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    let chatconatinerheight = document.querySelector(".bxTabHeight.bxChat .tl");

    if (chatconatinerheight) {
    }
  });
  const setcommentoffset = (offset) => {
    newoffset = offset;
  };
  const handleFormSubmit = (e) => {
    const editor = editorRef.current.getEditor();
    Incidence.loadcommentsubmit(
      Incidence.selectedCommentHtm,
      props.id,
      selectedFiles
    );
    editor.setText("");
    e.preventDefault();
  };

  const handleChange = (html) => {
    Incidence.selectedCommentHtm = html;
  };

  const paginationscroll = (event) => {
    let chatconatinerheight = document.querySelector(".bxTabHeight.bxChat .tl");
    if (chatconatinerheight.scrollTop === 0) {
      if (props.total) {
        setcommentoffset(newoffset + 5);
      }
      Incidence.loadactivites(props.id, newoffset);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleExpandClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const headerRegex = /<header>(.*?)<\/header>/;
  const headerMatch = modalContent?.match(headerRegex);

  // Fetch the header content
  const headerContent = headerMatch ? headerMatch[0] : "Details";

  // Remove the <header> from the string
  const updatedHtmlcone = modalContent?.replace(headerRegex, "");

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      setSelectedFiles([file]); // Replace the current file
    }
  };


  const handleRemoveFile = () => {
    setSelectedFiles([]); // Clear the selected file
  };

  return (
    <>
      <div
        className="tab-pane fade show"
        id="activities-tab-pane"
        role="tabpanel"
        aria-labelledby="activities-tab"
        tabIndex="0"
      >
        <div className="bxTabHeight bxChat" id="activitescommentsection">
          <div className="tl" onScroll={paginationscroll}>
            <div className="bnfx_incident_activities_pagination"></div>
            <div className="bnfx_incident_activities_pagination">
              <IncidenceActivitesChat
                data={result}
                onExpand={handleExpandClick}
              />
            </div>
          </div>
        </div>

        <div className="comment-area-box-sec">
          <div className="col-12">
            <form
              action="#"
              className="comment-area-box"
              id="comment_name"
              onSubmit={handleFormSubmit}
            >
              <ReactQuill onChange={handleChange} ref={editorRef} />
              <div className="file-upload-section">
                <label htmlFor="file-upload">
                  <span className="material-symbols-outlined">upload</span>
                </label>
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                
                <div className="selected-files">
                  {selectedFiles.length > 0 && (
                    <div className="selected-file">
                      <span>{selectedFiles[0].name}</span>
                      <button
                        type="button"
                        className="remove-file-btn"
                        onClick={handleRemoveFile}
                      >
                        <span className="material-symbols-outlined">cancel</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-sm btn-primary">
                <span className="material-symbols-outlined">send</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      <AutomationSummaryModal
        show={showModal}
        onClose={() => setShowModal(false)}
        bodyContent={
          <div dangerouslySetInnerHTML={{ __html: updatedHtmlcone }} />
        }
        header={<div dangerouslySetInnerHTML={{ __html: headerContent }} />}
      />
    </>
  );
}

export default IncidenceActivites;
