// Import necessary dependencies
import { showError } from "../../components/common/DisplayError";
import EpsComponent from "../../components/common/EpsComponent";
import { getGlobalState, setGlobalState } from "../../components/common/GlobalVariables";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax } from "../../components/common/Service";
import ToastPopup from "../../components/common/ToastPopup";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import Collectors from "./Collectors";
import CollectorsTab from "./CollectorsTabs";
import CollectorsTenants from "./CollectorsTenant";
import CollectorInstructionForm from "./CollectorInstructionForm";
import ConnectionNode from "./ConnectionNode";
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import { t } from "i18next";

// Collectors module provides functions for handling data related to collectors.
let LLMSTABLE = process.env.REACT_APP_LLM_STABLE;
export const CollectorsScript = {
  // Variable to store the content of the configuration file
  filecontent: null,
  defaulttenant:null,
  previuseps:null,


  // Initialize the collectors module
  init: async () => {
    let options = null;
    let tenants = CollectorsScript.gettenants(); // Get the list of tenants
    if(tenants.length===1){
      let tenatlist={
        value: tenants[0],
        label: tenants[0],
      }
      CollectorsScript.loadnodesandconfig(tenatlist)
   }else{
    if (tenants.length === 0) {
      let tenant = CommonTemplate.getListTenant();
      let  obtainedtenantlist = await Promise.all([tenant]);

      let  listtenant = obtainedtenantlist[0].data.response;
     
      options = listtenant.map((tenant, index) => ({
        value: tenant,
        label: tenant.name,
      }));

    } else if(tenants.length >1) {
      options = tenants.map((tenant, index) => ({
        value: tenant,
        label: tenant,
      }));
    }
    const container = document.querySelector("#bnfx-collector-tenantlist");
    const data =

      <CollectorsTenants
        tenants={options}
      />
    CommonTemplate.ContainerAvailability(container, data);

    CollectorsScript.loadnodesandconfig(options[0])}
    
  },

  loadactivenode: (tenant) => {
    // Performs an AJAX POST request to fetch the active node data for the tenant.
    return ajax(
      "post",
      requestURI["collectors.loadactivenode"],
      null,
      { tenant: tenant },
      null,
      null,
      null,
      true
    );
  },

  // Retrieves the INI configuration for a specified tenant.
  getiniconfig: (tenant) => {
    // Performs an AJAX POST request to fetch the INI configuration data for the tenant.
    return ajax(
      "post",
      requestURI["collectors.getiniconfig"],
      null,
      { tenant: tenant },
      null,
      null,
      null,
      true
    );
  },
  loadinitoolsconfig: (tenant) => {
    // Performs an AJAX POST request to fetch the active node data for the tenant.
    return ajax(
      "post",
      requestURI["automation.getiniconfig"],
      null,
      { tenant: tenant },
      null,
      null,
      null,
      true
    );
  },

  // Retrieves a list of all available tenants for the user.
  gettenants: () => {
    // Uses the CommonTemplate module to get the list of user tenants.
    return CommonTemplate.getUserTenants();
  },

  loadnodesandconfig: async (tenant) => {
    CollectorsScript.defaulttenant=tenant;
    try {
      // Fetch INI configuration data for the tenant.
      const iniconfigPromise = CollectorsScript.getiniconfig(tenant.label);
      // Fetch active nodes data for the tenant.
      const activenodesPromise = CollectorsScript.loadactivenode(tenant.label);
      let  toolsconfigPromise=null
      if (LLMSTABLE==="true") {
        toolsconfigPromise = CollectorsScript.loadinitoolsconfig(tenant.label);
      }

      // Wait for both promises to resolve
      const [iniconfig, activenodes,toolsconfig] = await Promise.all([
        iniconfigPromise,
        activenodesPromise,
        LLMSTABLE==="true"?toolsconfigPromise:null
      ]);

      // Container availability for collectors tab content
      // const collectordata = { "iniconfig": iniconfig.data.response, "activenodes": activenodes.data.response };
      let data = (
        <CollectorsTab
          config={iniconfig.data.response}
          activenodes={activenodes.data.response}
          tenant={tenant.label}
          {...(LLMSTABLE==="true" ? { toolsconfig: toolsconfig.data.response } : {})}
        />
      );
      CommonTemplate.ContainerAvailability(
        document.querySelector("#collectors-tab-content"),
        data
      );
    } catch (error) {
      // Handle any errors that occur during fetching
      console.error("Error:", error);
    }
  },

  // Saves the configuration for a specified tenant.
  saveconfig: (tenant,config,updatedToolsConfig) => {
    let connectionNode = document.querySelector(".connectionNode");
    if (connectionNode?.classList?.contains("active")) {
      ajax(
        "post",
        requestURI["collectors.saveconfig"],
        null,
        { tenant: tenant, config: CollectorsScript.filecontent?CollectorsScript.filecontent:config },
        () => {
          // Display a success toast popup message when the configuration is saved successfully.
          ToastPopup("Connection Saved Successfully");
        },
        // Display an error using the showError function if the request fails.
        showError,
        null,
        false
      );
    } else {
      ajax(
        "post",
        requestURI["automation.savetoolsconfig"],
        null,
        { tenant: tenant, config: updatedToolsConfig },
        () => {
          // Display a success toast popup message when the configuration is saved successfully.
          ToastPopup("Tool Configuration Saved Successfully");
        },
        // Display an error using the showError function if the request fails.
        showError,
        null,
        false
      );
      
    }
  
  },

  // Set the content of the configuration file
  setIniConfig: (value) => {
    CollectorsScript.filecontent = value;
  },

  getEncryptData:(selecttext)=>{
    
    const request={
      plaintext:selecttext,
      tenant:CollectorsScript.defaulttenant.label
    }

    ajax(
      "post",
      requestURI["collector.encrypt"],
      null,
      request,
      CollectorsScript.renderEncryptData,
      showError,
    );
  },

  renderEncryptData:(response)=>{
    let encryptValue=response.data.response;
    setGlobalState("encryptValue",encryptValue)
    
  },

  openTab: (evt, tabName) => {
    var i, tabcontent, tablinks;

    // Hide all tab contents
    tabcontent = document.getElementsByClassName("tab-content collector");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList?.remove("active");
    }

    // Remove active class from all tab links
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].classList?.remove("active");
    }

    // Show the selected tab content
    document.getElementById(tabName).classList?.add("active");

    // Add active class to the current tab link if evt is not null
    if (evt && evt.currentTarget) {
      evt.currentTarget.classList?.add("active");
    }

    // Additional logic based on tabName
    let addclass = document.querySelector(".collector-savebutton");
    let addiniconfig = document.querySelector(".add-collector-ini-config");
    if (tabName === "tools-ini-config-editor") {
      addiniconfig.classList?.add("d-none");
      addclass.classList?.add("active");
    } else if (tabName === "collectors-ini-editor") {
      addiniconfig.classList?.remove("d-none");
      addclass.classList?.add("active");
    
    } else {

      addclass.classList?.remove("active");
      addiniconfig.classList?.add("d-none");
    } 
},


  loadconnector: () => {
    let data = <Collectors />;
    CommonTemplate.ContainerAvailability(
      document.querySelector("#bnfx-connector-panel"),
      data
    );
  },

  autorefresh:()=>{
    CollectorsScript.loadnodesandconfig(CollectorsScript.defaulttenant)
  },
  handleRefresh:()=> {
    CollectorsScript.loadnodesandconfig(CollectorsScript.defaulttenant)
  },

  getEps: () => {
    let requests={}
    ajax(
      "post",
      requestURI["collectors.eps"],
      null,
      requests,
      CollectorsScript.renderEventsPerSecond,
      showError
    );
  },

  renderEventsPerSecond: async (result) => {
    let eps = result.data.response;
    if(CollectorsScript.previuseps!==eps){
      CollectorsScript.previuseps=eps
      let totalToday=await CollectorsScript.gettotalToday()
      let data=<EpsComponent eps={eps} totaldata={totalToday.data.response}/>;
      CommonTemplate.ContainerAvailability(
      document.querySelector("#bnfxeventspersecond"),
      data);
  }
  },
    // Retrieves the INI configuration for a specified tenant.
    gettotalToday: () => {
      // Performs an AJAX POST request to fetch the INI configuration data for the tenant.
      return ajax(
        "post",
        requestURI["collectors.totaltoday"],
        null,
        { },
        null,
        null,
        null,
        true
      );
    },
    formatNumber: (value) => {
      if (value >= 1e12) {
        return (value / 1e12).toFixed(1) + "T";
      } else if (value >= 1e9) {
        return (value / 1e9).toFixed(1) + "B";
      } else if (value >= 1e6) {
        return (value / 1e6).toFixed(1) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(1) + "K";
      } else {
        return value?.toString();
      }
    },

    getInstruction:()=>{
      
    // const container=document.querySelector(".offcanvas.offcanvas-end.dataCollectors");
    // if(container){
    //   container.classList.add("show")
    // }
      const request={
       tenant:CollectorsScript.defaulttenant.label
      }
  
      ajax(
        "post",
        requestURI["collector.instruction"],
        null,
        request,
        CollectorsScript.renderInstructionData,
        showError,
      );
    },
  
    renderInstructionData:(response)=>{
    let result=response.data.response;

    const container = document.querySelector("#bnfxAddInstructions");
    const data =<CollectorInstructionForm result={result}/>

    CommonTemplate.ContainerAvailability(container, data);
  },

  getConnectionData:(connectionData)=>{
    CollectorsScript.getConnectionType(connectionData);
    
  },

  getCollectorSuggest:()=>{
    let getconnectiondata=getGlobalState("getconnectiondata");
    
    const request={
      "arguments":{
        provider: document.getElementById("provider")?.value,
        group: document.getElementById("group")?.value,
        type: document.getElementById("type")?.value,
        format: document.getElementById("format")?.value,
        retention: document.getElementById("retention")?.value,
        connection:getconnectiondata?.connection
      },
      tenant:CollectorsScript.defaulttenant.label 
    }

    const { provider, group, type, format, connection } = request.arguments;

    if (!provider || !group || !type || !format || !connection || 
        !connection.syslog?.port || !connection.syslog?.protocol) {
          ShowErrorToastPopup(t("fill_the_field_msg"));
        return;
    }

    CollectorsScript.showInstructionPanel();
    ajax(
      "post",
      requestURI["collector.suggest"],
      null,
      request,
      CollectorsScript.renderCollectorSuggest,
      showError,
    );
  },

  renderCollectorSuggest:(response)=>{
  let result=response.data.response;
  let combinedContent = CollectorsScript.filecontent.trim() + '\n\n'+ result.trim();
  CollectorsScript.setIniConfig(combinedContent);

  const container = document.querySelector("#collectors-ini-editor");
  const data =<ConnectionNode config={combinedContent}/>
  CommonTemplate.ContainerAvailability(container, data);
  ToastPopup("Instruction create successfully")
},

showInstructionPanel:()=>{
  CollectorsScript.openTab(null, "collectors-ini-editor");

  const container=document.querySelector(".offcanvas.offcanvas-end.dataCollectors");
    if(container){
      container.classList.add("show")
    }

    const showconnectionNode=document.querySelector(".tab-content.collector.connectionNode.h-100");
    if(showconnectionNode){
      showconnectionNode.classList.add("active")
    }

    const show_tabactive=document.querySelector(".tab.connectionNode");
    if(show_tabactive){
      show_tabactive.classList.add("active")
    }
    
    const showInstructionsPanel=document.querySelector(".bxStreamLstOffCanvas.offcanvas.offcanvas-end.AddNewInstructionsPanel");
    if(showInstructionsPanel){
      showInstructionsPanel.classList.remove("show")
    }
},

removeInstructionPanel: () => {
  let removedataCollectors = document.querySelector(
    ".offcanvas.offcanvas-end.dataCollectors"
  );
  if (removedataCollectors) {
    removedataCollectors.classList.remove("show");
  }
  let showInstructionsPanel = document.querySelector(
    ".bxStreamLstOffCanvas.offcanvas.offcanvas-end.AddNewInstructionsPanel"
  );
  if (showInstructionsPanel) {
    showInstructionsPanel.classList.add("show");
  }
},

removePanel: () => {
  let removedataCollectors = document.querySelector(
    ".offcanvas.offcanvas-end.dataCollectors"
  );
  if (removedataCollectors) {
    removedataCollectors.classList.remove("show");
  }
},
  
};
