import React, { useEffect } from 'react';
import DashboardEventTimeline from '../../../components/common/GridPanel.js/DashboardEventTimeline';


function TimelineEvents(props) {

  let data = null;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });
  
  if (props.output) {
    data =
    props.output.map((dataObj, index) => (
      <React.Fragment key={index}>
        <DashboardEventTimeline data={dataObj} index={index}/>
      </React.Fragment>
    ))
  }

  return (
    <>
     <div className="bnfxDESDetails pb-5">
      <div className="bnfxDectionsEvents mt-0 timelineEvent">
        {data}
      </div>
      </div>
    </>
  );
}

export default TimelineEvents;
