
function TimelineTab() {

    return (
      <>
      
         <div id="bnfxloadSearch">{/* here we replace detection search */}</div>
        <div id="bnfxArtifactsList"></div>

        <div
          className="bnfxDESDetails pb-5"
          id="bnfxDetectionEventStreamDetails"
        >
          {/* here we replace detection events */}
        </div>
        <div id="bnfxPaginationEventStreamDetails">
          {/* here we repalce pagination */}
        </div>
      </>

    );
  }
  
  export default TimelineTab;
  