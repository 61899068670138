  import { useEffect, useRef } from "react";
  import { Stream } from "../StreamScript";

  function DetectionList(props) {
    let output = props.output;
    let criticalitystat = output?.criticalitystat;
    let detectionstat = output?.detectionstat;
    let detectiontrend = output?.detectiontrend;
    let summary = output?.summary;
    let stream = props.stream;
    const summaryRef = useRef(null);

    useEffect(() => {
      Stream.redirectsummary(criticalitystat, detectionstat, detectiontrend);
    }, [criticalitystat, detectionstat, detectiontrend]);

    useEffect(() => {
      if (summaryRef.current) {
        const entities = summaryRef.current.querySelectorAll(".llm_entity, .llm_detection");
        entities.forEach(entity => {
          entity.setAttribute("data-bs-target", "#bxOffcanvasPanel");
          entity.setAttribute("data-bs-toggle", "offcanvas");
        });
      }
    }, [summary]);

    const handleEntityClick = (event) => {
      const target = event.target;
      if (target.classList.contains("llm_entity")) {
        const dataKind = target.getAttribute("data-kind");
        const dataVal = target.getAttribute("data-val");
        const datastream = target.getAttribute("data-stream");
  
        let data = {
          entity: dataVal,
          entitytype: dataKind,
          streamid: datastream,
          tenant: stream.tenant,
        };
  
        Stream.loadStreamrowOnclick(data, "entity");
      } else if (target.classList.contains("llm_detection")) {
        const detectionid = target.getAttribute("data-val");
        const datastream = target.getAttribute("data-stream");
        const detectionname = target.textContent || target.innerText;
  
        let detectiondata = {
          detectionid: detectionid,
          streamid: datastream,
          tenant: stream.tenant,
          name: detectionname
        };
        Stream.loadStreamrowOnclick(detectiondata, "detection");
      }
    };
    return (
      
      <>
        <div className="DopplerInputFiled total_Summary mb-4">
        <div
          className="m-0"
          onClick={handleEntityClick}
          dangerouslySetInnerHTML={{ __html: summary }}
          ref={summaryRef}
        ></div>
      </div>
      </>
    );
  }

  export default DetectionList;
