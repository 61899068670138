// ToastPopup.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ToastPopup(message,position) {
  toast.success(message, {
    position: 'top-right',
    autoClose: position ? false : 3000, // If position is provided, disable autoClose
    hideProgressBar: !!position,
    pauseOnHover: false,
    style: {
      background: '#061731',
      color: '#FFFF', 
    },
  });
}

export default ToastPopup;