import React from 'react';

const EventDetailsWidget = (props) => {
    const rowData = props.data;
    const columns = props.columns;
    
    return (
        <div className="eventDetailsLayout">
            <div className="bxTabelRelations">
                <table className="table table-centered mb-0">
                    <thead className="table-dark">
                        <tr className="fatal_header">
                            {columns?.map((name, index) => (
                                <th className="fatal-column" key={index}>
                                    {name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="tableBodyData">
                        {rowData?.map((row, rowIndex) => (
                            <tr className="fatal-row" key={rowIndex}>
                                {columns?.map((colName, colIndex) => (
                                    <td className="fatal-column" key={colIndex}>
                                        {row[colName]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EventDetailsWidget;