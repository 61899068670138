import ColumnChart from "../../../components/highChart/ColumnChart";
import LineChart from "../../../components/highChart/LineChart";
import NetworkGraph from "../../../components/highChart/NetworkGraph";
import PieChart from "../../../components/highChart/PieChart";
import BubbleChart from "../../../components/highChart/BubbleChart";
import TableList from "../../../components/common/TableList";
import InvalidWidget from "../../../components/common/InvalidWidget";
import DashboardCard from "./DashboardCard";
import { useEffect } from "react";
import SprialChart from "../../../components/highChart/SpiralChart";
import { Dashboard } from "../DashboardScript";
import TreeMap from "../../../components/highChart/TreeMap";
import AreaChart from "../../../components/highChart/AreaChart";
import CriticalWidgetLayout from "./CriticalWidgetLayout";
import DetectionBubbleWidget from "./DetectionBubbleWidget";
import IncidentDetailWidget from "./IncidentDetailWidget";
import RoiTableList from "../../../components/common/RoiTableList";
import RoiCard from "./RoiCard";
import MitreWidget from "./MitreWidget";
import DashboardTrendLayout from "./DashboardTrendLayout";
import Scatterplot from "../../../components/highChart/Scatterplot";
import MyqueWidget from "./MyqueWidget";
import BarChart from "../../../components/highChart/BarChart";
import SankeyDiagram from "../../../components/highChart/SankeyDiagram";
import SingleColumnChart from "../../../components/highChart/SingleColumnChart";
import DonutChart from "../../../components/highChart/DonutChart";
import MiterDashboardWidget from "./MiterDashboardWidget";
import WordCloud from "../../../components/highChart/WordCloud";
import FunnelCustomize from "../../../components/common/funnel/FunnelCustomize";
import StackedBar from "../../../components/highChart/StackedBar";
import EventTypeStats from "./EventTypeStats";
import EventDetailsWidget from "./EventDetailsWidget";


function WidgetDataLayout(props) {
   
    let datalayout = null;
    let widget=props.widget;
    let data=props.data;
    let widget_type=widget.configure.properties.type
    let layout=widget.configure.properties.layout
    let graphtype=widget.configure.properties.graphtype
    let onclick_param=widget.configure.properties.onclick
    useEffect(() => {
        if (props.callback) {
          props.callback();
        }
      });
      
    if (widget_type === 'card') {
        datalayout = <DashboardCard data={data} id={widget.identifier}/>
    } else if (widget_type === 'table') {
        datalayout = <TableList data={data} id={widget}  onClickFn={Dashboard.openingpanelonclick}/>
    }else if (widget_type === 'my_queue') {
        datalayout = <MyqueWidget data={data.data.response?.result?.result} id={widget}   onClickFn={Dashboard.openingpanelonclick}/>
    } else if (widget_type === 'column') {
        datalayout = <ColumnChart series={data.data.response?.result?.result?.series} uniquekey={data.data.response?.result} columnmap={data.data?.response?.result?.columnmap} column={data.data.response?.result?.column} label={data.data.response?.result?.label} id={widget.identifier} categories={data.data.response?.result?.result?.categories} onclick_param={onclick_param} widget_type="" colors={["#fee17e", "#3d9c73", "#63b179", "#88c580","#d6ec91","#ffff9d","#fee17e"]} container={'bnfxWidgetContainer-' + widget.identifier}layout={layout} height={null} onClickFn={Dashboard.leftSidePanelClick}/>
    } else if (widget_type === 'line') {
        datalayout = <LineChart series={data.data.response?.result?.result} categories={data.data.response?.result.result?.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} layout={layout}/>
    }else if (widget_type === 'area') {
        datalayout = <AreaChart series={data.data.response?.result.series} categories={data.data.response?.result.categories} colors={data.data.response?.result.colors} container={'bnfxWidgetContainer-' + widget.identifier}  published_sum={data.data.response?.result.published_sum} layout={layout} height={null}/>
    }else if (widget_type === 'statcard') {
        datalayout = <DashboardTrendLayout id={widget.identifier} series={data.data.response?.result?.result.series} categories={data.data.response?.result?.result.categories} colors={data.colors} total={data.data.response?.result?.result?.total} name={data.data.response?.result?.result?.name} container={'bnfxWidgetContainer-' + widget.identifier} graphtype={graphtype}/>
    }else if (widget_type === 'mitre') {
        datalayout = <MitreWidget data={data.data.response}  container={'bnfxWidgetContainer-' + widget.identifier} />
    }else if (widget_type === 'critical') {
        datalayout = <CriticalWidgetLayout criticaldata={data.data.response?.result} categories={data.data.response?.result.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} />
    }else if (widget_type === 'venn') {
        datalayout = <DetectionBubbleWidget data={data.data.response?.result} categories={data.data.response?.result.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} />
    } else if (widget_type === 'networkgraph') {
        datalayout = <NetworkGraph series={data.series} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} />
    } else if (widget_type === 'pie') {
        datalayout = <PieChart series={data.data.response?.result?.result} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} layout={layout} applyfilteronClick={Dashboard.applytypeasfilter} filterid={"detectiontactic"}/>
    }else if (widget_type === 'bar') {
        datalayout = <BarChart series={data.data.response?.result?.series} uniquekey={data.data.response?.result} columnmap={data.data?.response?.result?.columnmap}  column={data.data.response?.result?.column} label={data.data.response?.result.label} id={widget.identifier} categories={data.data.response?.result.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} layout={layout} height={null} hidex={null} onClickFn={Dashboard.leftSidePanelClick}/>
    }else if (widget_type === 'stackedbar') {
        datalayout = <StackedBar series={data.data.response?.result?.series} uniquekey={data.data.response?.result}  column={data.data.response?.result?.column} label={data.data.response?.result.label} id={widget.identifier} categories={data.data.response?.result.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} layout={layout} height={null} hidex={null} onClickFn={Dashboard.leftSidePanelClick}/>
    }else if (widget_type === 'sankey') {
        datalayout = <SankeyDiagram series={data.data.response?.result?.series} uniquekey={data.data.response?.result} column={data.data.response?.result?.column} columnmap={data.data?.response?.result?.columnmap} onclick_param={onclick_param} label={data.data.response?.result.label} id={widget.identifier} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} onClickFn={Dashboard.leftSidePanelClick}/>
    }else if (widget_type === 'treemap') {
        datalayout = <TreeMap series={data.data.response?.result?.result} uniquekey={data.data.response?.result} columnmap={data.data?.response?.result?.columnmap} column={data.data.response?.result?.column} onclick_param={onclick_param} label={data.data.response?.result?.label} id={widget.identifier} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} layout={layout} onClickFn={Dashboard.leftSidePanelClick}/>
    }else if (widget_type === 'bubble') {
        datalayout = <BubbleChart series={data.data.response?.result?.results} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} id={widget.identifier} onclick_param={onclick_param} applyfilteronClick={Dashboard.applytypeasfilter} filterid={"detectionname"}/>
    } else if (widget_type === 'spiral') {
        datalayout = <SprialChart series={data.data.response?.result?.result?.series} categories={data.data.response?.result?.result?.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} layout={layout}height={null} applyfilteronClick={Dashboard.applytypeasfilter} filterid={"detectionname"}/>
    }else if (widget_type === 'donut') {
        datalayout = <DonutChart data={data.data.response?.result?.result} categories={data.data.response?.result.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} layout={layout} height={null} applyfilteronClick={Dashboard.applytypeasfilter} filterid={"detectioncriticality"}/>
    } else if (widget_type === 'incident_list') {
        datalayout = <IncidentDetailWidget incidentdata={data.data.response} categories={data.data.response?.result.categories} colors={data.colors} container={'bnfxWidgetContainer-' + widget.identifier} />
    }else if (widget_type === 'concise_table') {
        datalayout = <RoiTableList data={data.data.response?.result?.result} id={widget.identifier} />
    }else if (widget_type === 'roi_card') {
        datalayout = <RoiCard data={data.data.response?.result} id={widget.identifier} />
    }else if (widget_type === 'scatterplot') {
        datalayout = <Scatterplot  data={data.data.response?.result?.result} id={widget}  onClickFn={Dashboard.openingpanelonclick} />
    }else if (widget_type === 'singlecolumn') {
        datalayout = <SingleColumnChart  series={data.data.response?.result?.series} id={widget.identifier} categories={data.data.response?.result?.categories} colors={["#00876c", "#3d9c73", "#63b179", "#88c580", "#aed987","#003F5C","#004c6d","#006083","#007599","#88c580","#aed987","#d6ec91","#ffff9d","#fee17e"]} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} layout={layout} height={null} applyfilteronClick={Dashboard.applytypeasfilter} filterid={"detectiontechnique"} />
    }else if (widget_type === 'threatcoverage_mitre') {
        datalayout = <MiterDashboardWidget data={data.data.response.result.result}  container={'bnfxWidgetContainer-' + widget} onClickFn={Dashboard.openingpanelonclick} id={widget.identifier} />
    }else if (widget_type === 'wordcloud') {
        datalayout = <WordCloud data={data.data?.response?.result} column={data.data?.response?.result?.column} label={data.data?.response?.result?.label} columnmap={data.data?.response?.result?.columnmap} layout={layout} height={null} id={widget.identifier} container={'bnfxWidgetContainer-' + widget.identifier} onclick_param={onclick_param} onClickFn={Dashboard.leftSidePanelClick}/>
    }else if (widget_type === 'funnel') {
        datalayout = <FunnelCustomize series={data.data.response.result.result}  container={'bnfxWidgetContainer-' + widget.identifier} height={null} layout={layout} hidex={true}/>
    }else if (widget_type === 'fatalwidget') {
        datalayout = <EventDetailsWidget data={data.data?.response?.result?.result} columns={data.data?.response?.result?.columns} id={widget.identifier} container={'bnfxWidgetContainer-' + widget.identifier}/>
    }else if (widget_type === 'eventtype_stats') {
        datalayout = <EventTypeStats data={data.data?.response?.result?.result} id={widget.identifier} container={'bnfxWidgetContainer-' + widget.identifier}/>
    }else {
        datalayout = <InvalidWidget />
    }

    return (
        <>
            {datalayout}
        </>
    );
}

export default WidgetDataLayout;
