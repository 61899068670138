import React, { useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";

const PieChart = ({ series, container,onclick_param,layout,applyfilteronClick,filterid}) => {
  const chartContainer = useRef(null);

  const handleRowClick = (data) => {
    applyfilteronClick(filterid,data);
  };
  
  let colorslist=series?.length<5?[ "#63b179","#d6ec91","#ffff9d","#fee17e"]:
      ["#003F5C","#004c6d","#006083","#00cfe3","#88c580","#aed987","#d6ec91","#ffff9d",
        "#fee17e","#00876c","#3d9c73","#63b179","#fcc267","#f7a258","#ef8250",
        "#008bad","#00a1c1","#00b8d3","#e4604e","#d43d51"];
  
  useLayoutEffect(() => {
    let height = null;
    if (chartContainer.current && series?.length > 0) {

      const randomslice = Math.floor(Math.random() * series.length);
      series[randomslice].sliced = true;
      const parent = container.replace(
        "bnfxWidgetContainer",
        "grid-stack-item"
      );
      if (layout==='conciselayout') {
        
        height = parseInt(document.getElementById(parent)?.clientHeight) - 70;
      }
      else{
        height = parseInt(document.getElementById(parent)?.clientHeight) - 150;
      }
      Highcharts.chart(chartContainer.current, {
        chart: {
          type: "pie",
          height: height + "px",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: {point.percentage:.1f}%",
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            colors: colorslist,
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: layout==='conciselayout'?false:true,
              color: "#ffff",
              connectorColor: "#659CC8",
              formatter: function () {
                return this.point.name;
              },
            },
            borderWidth: 0,
            events: {
              click: function (event) {
                if (onclick_param === 'filter_apply') {
                handleRowClick(event.point.name); // Call your click handler with the point name
                }
              }
            }
          },
        },
        series: [
          {
            name: "",
            data: series,
          },
        ],
      });
    }
  }, [series, colorslist, container,layout]);

  return <div ref={chartContainer} id={container} />;
};

export default PieChart;
