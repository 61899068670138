import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";

const ColumnChart = (props) => {
  const chartContainer = useRef(null);
  const colors=props.colors;
  const series=props.series;
  const categories=props.categories;
  const container=props.container;
  const height=props.height;
  const widgetid=props.id
  const column=props.column;
  const label=props.label;
  const onclick_param=props.onclick_param;
  let layout=props.layout;
  const columnmap=props.columnmap;

    const handleNameClick = (name,point) => {
      const modifiedPoint = {
        ...point,
        name: point.series.name,
        series: {
            proto: point.series
        }
    };
      let dataset = { name: name, column: column, label: label ,data:props.uniquekey,point:modifiedPoint,columnmap:columnmap};
      
      props.onClickFn(dataset, widgetid);
    };


  useEffect(() => {
    const updateChartHeight = () => {
      let widgetheight = null;
      const width =  document.getElementById(container)?.closest(".bxDashboardGraph")?.clientWidth - 80;
      
      if (chartContainer.current) {
        const parent = container.replace(
          "bnfxWidgetContainer",
          "grid-stack-item"
        );
        if (layout === "conciselayout") {
          widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 70;
        } else {
          widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 120;
        }

        if (Array.isArray(colors) && colors.length > 0) {
          Highcharts.chart(chartContainer.current, {
            colors: colors,
            chart: {
              type: "column",
              height: height ? height + "px" : widgetheight + "px",
              events: {
                load: function () {
                  if (onclick_param === 'open_offcanvaspanel') {
                    this.series.forEach((series) => {
                      series.points.forEach((point) => {
                        if (point.graphic && point.graphic.element) {
                          point.graphic.element.addEventListener(
                            "mousedown",
                            () => {
                              point.graphic.element.setAttribute(
                                "data-bs-toggle",
                                "offcanvas"
                              );
                              point.graphic.element.setAttribute(
                                "data-bs-target",
                                "#bxOffcanvasPanel"
                              );
                              handleNameClick(point.category,point);
                            }
                          );
                        }
                      });
                    });
                  }
                },
              },
            },
            title: {
              text: null,
            },
            subtitle: {
              text: null,
            },
            xAxis: {
              categories: categories,
              visible: true,
              labels: {
                style: {
                  fontSize: "8px",
                  color: "#ffffff",
                },
                y: 11,
              },
              tickInterval: width > 1000 
                ? (
                    categories?.length > 100
                      ? 10
                      : categories?.length > 10 && categories.length <= 100
                      ? 4
                      : null
                  )
                : (
                  categories?.length > 100
                    ? 20
                    : categories?.length > 20 && categories.length <= 100
                    ? 10
                    : categories?.length > 10 && categories.length <= 20
                    ? 4
                    :null
                )
            },
            yAxis: {
              visible: false,
              maxPadding: 0,
              minPadding: 0,
              endOnTick: false,
              labels: {
                enabled: false,
              },
            },
            // yAxis: {
            //   visible: false,
            //   maxPadding: 0,
            //   minPadding: 0,
            //   endOnTick: false,
            //   type: 'logarithmic',
            //   min: 1, // Set the minimum value to avoid log(0)
            //   title: {
            //     text: ''
            //   },
            //   gridLineWidth: 0,
            //   labels: {
            //     formatter: function() {
            //       return Highcharts.numberFormat(this.value, 0, '.', ','); // Format numbers with commas
            //     }
            //   }
            // },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              column: {
                borderWidth: 0,
                borderRadius: 0,
                stacking: "normal",
                pointPadding: 0.2,
                groupPadding: 0.1,
              },
            },
            series: series?.map((seriesObj, index) => ({
              ...seriesObj,
              color: colors[index % colors.length],
            })),
          });
        }
      }
    };

    updateChartHeight();

    window.addEventListener("resize", updateChartHeight);

    return () => {
      window.removeEventListener("resize", updateChartHeight);
    };
  }, [series, categories, colors, container,height,onclick_param]);

  return (
    <div
      ref={chartContainer}
      id={container}
      style={{ backgroundColor: "transparent" }}
    />
  );
};


export default ColumnChart;

