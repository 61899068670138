import Highcharts from "highcharts";
import { useLayoutEffect, useRef } from "react";

const AreaChart = (props) => {
  const chartRef = useRef(null);
  let container=props.container;
  let series=props.series;
  let categories=props.categories;
  let height=props.height;
  let layout=props.layout;
  let colors=props.colors?props.colors:[ "#006083", "#007599", "#88c580","#eacc62","#00876c", "#3d9c73", "#63b179"];
  
  // const series = [
  //   { data: [5, 2, 31], name: "USA" },
  //   { data: [15, 20, 5,24,8], name: "INDIA" }
  // ];

  // const categories = ["abhishek", "ayushman", "arun", "vishal", "ziyad",];

  useLayoutEffect(() => {
    let widgetheight = null;
    if (chartRef.current) {
      const parent = container.replace("bnfxWidgetContainer", "grid-stack-item");

      if (layout === "conciselayout") {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 70;
      } else {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 120;
      }

      Highcharts.chart(chartRef.current, {
        chart: {
          type: "areaspline",
          height: height ? height + "px" : widgetheight + "px",
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: categories,
          tickInterval: categories?.length <= 10 ? 2 : 8,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function () {
              const label = this.value;
              const maxLength = 5; // Set the maximum length for the label
              const truncatedLabel =
                label.length > maxLength
                  ? label.substring(0, maxLength) 
                  : label;
              return `<span title="${label}">${truncatedLabel}</span>`;
            },
            style: {
              color: "#b3771a",
              textOverflow: "ellipsis",
            },
          },
          type: "datetime", // set the x-axis type to datetime
          dateTimeLabelFormats: {
            day: "%e %b", // Format for the day
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
                if (this.value >= 1000000) {
                    return (this.value / 1000000) + 'M'; // Format for millions
                } else if (this.value >= 1000) {
                    return (this.value / 1000) + 'k'; // Format for thousands
                } else {
                    return this.value; // Use the value as is for smaller numbers
                }
            }
        }
        },
        legend: {
          enabled: false,
      },
        tooltip: {
          pointFormat: ": <b>{point.y:,.0f}</b><br/>",
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
            },
          },
        },
        series: series?.map((serie, serieIndex) => ({
          name: serie.name,
          data: serie.data.map((value, index) => ({
            y: value,
            color: colors[index % colors.length],  // Ensure color is explicitly set
          })),
          color: colors[serieIndex % colors.length],  // Optional: set a base color for the series
        })),
      });
    }
  }, [series, categories, container, height, layout,colors]);

  return <div ref={chartRef} id={container} />;
};

export default AreaChart;
