import { ajax } from "../../components/common/Service";
import { showError } from "../../components/common/DisplayError";
import { requestURI } from "../../components/common/RequestURIs";
import $ from "jquery";
import SearchLoadPage from "./SearchLoadPage";
import SearchEvent from "./SearchEvent";
import Pagination from "../../components/common/pagination/Pagination";
import NoContent from "../../components/common/NoContent";
import SearchDateLayout from "./SearchDateLayout";
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import TrendChart from "../../components/highChart/TrendChart";
import {
  getGlobalState,
  setGlobalState,
} from "../../components/common/GlobalVariables";
import SearchDropdownLayout from "./SearchDropdownLayout";
import ToastPopup from "../../components/common/ToastPopup";
import { t } from "i18next";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import { DownloadsScript } from "../download/DownloadsScript";
import { Stream } from "../stream/StreamScript";
import { PanelScript } from "../../components/common/GridPanel.js/PanelScript";
import moment from "moment";

export const SearchTemplate = {
  default_startDate: null,
  default_endDate: null,
  startDate: null,
  endDate: null,
  searchSql: "",
  timetemplate: "",
  secheduleoption: null,
  timephrase:null,
  searchresulttotal: null,
  selectTableName:"detection",
  tablesList: [
  {value:"detection" ,label:"DETECTION"},
  {value:"entityscoring" ,label:"ENTITYSCORING"},
  {value:"incidentdetails" ,label:"INCIDENTDETAILS"},
  ],

  scheduleoptions: [
    { label: "DAILY", value: "@daily" },
    { label: "WEEKLY", value: "@weekly" },
    { label: "BIWEEKLY", value: "@biweekly" },
    { label: "MONTHLY", value: "@monthly" },
    { label: "SEMIANNUAL", value: "@semiannual" },
  ],

  setDefaultStartDate: (default_startDate) => {
    SearchTemplate.default_startDate = default_startDate;
  },

  setDefaultEndDate: (default_endDate) => {
    SearchTemplate.default_endDate = default_endDate;
  },
  setStartDate: (startDate) => {
    SearchTemplate.startDate = startDate;
  },

  setEndDate: (endDate) => {
    SearchTemplate.endDate = endDate;
  },

  setSearchQueryId: (searchqueryid) => {
    SearchTemplate.searchqueryid = searchqueryid;
  },

  clearVariables: () => {
    SearchTemplate.startDate = null;
    SearchTemplate.endDate = null;
    SearchTemplate.default_startDate = null;
    SearchTemplate.default_endDate = null;
  },

  searchSuggest: () => {
    SearchTemplate.clearVariables();

    ajax(
      "post",
      requestURI["search.autosuggest"],
      null,
      {},
      SearchTemplate.loadSearchLayout,
      showError
    );
  },
  loadSearchLayout: (response) => {
    const container = document.querySelector("#bnfxloadSearchPage");
    const data = <SearchLoadPage autosuggestions={response.data.response} />;
    CommonTemplate.ContainerAvailability(container, data);
  },

  renderSearchDatePicker: (current) => {
    SearchTemplate.timephrase="Last 24 Hours"
    const datepicker = $(current).data("daterangepicker");
    let defaultStartDate = datepicker?.startDate?.format("YYYY-MM-DD hh:mm A");
    let defaultEndDate = datepicker?.endDate?.format("YYYY-MM-DD hh:mm A");

    SearchTemplate.setDefaultStartDate(defaultStartDate);
    SearchTemplate.setDefaultEndDate(defaultEndDate);

    $(current).on("apply.daterangepicker", function (ev, picker) {
      SearchTemplate.timephrase=picker.chosenLabel
      const startDate = picker.startDate.format("YYYY-MM-DD hh:mm A");
      const endDate = picker.endDate.format("YYYY-MM-DD hh:mm A");

      SearchTemplate.setStartDate(startDate);
      SearchTemplate.setEndDate(endDate);

      return [startDate, endDate];
    });
  },


 calculateTimePhrase : (newStartDate, endDate) => {
  const startDate = moment(newStartDate, "YYYY-MM-DD hh:mm A");
  const endDateParsed = moment(endDate, "YYYY-MM-DD hh:mm A");
  
  const durationInHours = endDateParsed.diff(startDate, "hours");
  const durationInDays = endDateParsed.diff(startDate, "days");

  if (durationInDays === 0 && startDate.isSame(moment().startOf("day"), "day")) {
    return "Today";
  } else if (
    durationInDays === 1 &&
    startDate.isSame(moment().subtract(1, "days").startOf("day"), "day")
  ) {
    return "Yesterday";
  } else if (durationInHours === 24) {
    return "Last 24 Hours";
  } else if (durationInHours === 36) {
    return "Last 36 Hours";
  } else if (durationInHours === 48) {
    return "Last 48 Hours";
  } else if (durationInHours === 72) {
    return "Last 72 Hours";
  } else if (durationInDays === 6) {
    return "Last 7 Days";
  } else if (durationInDays === 29) {
    return "Last 30 Days";
  } else if (
    startDate.isSame(moment().startOf("month"), "month") &&
    endDateParsed.isSame(moment().startOf("hour"), "hour")
  ) {
    return "This Month";
  } else if (
    startDate.isSame(moment().subtract(1, "month").startOf("month"), "month") &&
    endDateParsed.isSame(moment().subtract(1, "month").endOf("month"), "month")
  ) {
    return "Previous Month";
  } else {
    return "Custom Range"; // If no predefined range matches
  }
},


  getSearchPage: (offset, query) => {
    let scoretimestamp=localStorage?.getItem("setScoreTimestamp");
    if(scoretimestamp){
    let newStartDate = moment(Number(scoretimestamp)).format("YYYY-MM-DD  hh:mm A");
    let endDate=SearchTemplate.endDate !== null? SearchTemplate.endDate: SearchTemplate.default_endDate;
    
    SearchTemplate.timephrase=SearchTemplate.calculateTimePhrase(newStartDate,endDate);
    SearchTemplate.setDefaultStartDate(newStartDate);
  }
  
    document.getElementById('search-loading-icon').style.display = 'block';
    document.getElementById('search-sendbutton').style.display = 'none';

    CommonTemplate.renderLoadingIcon("#bnfxSearchEvents")
    // document.getElementById('hunter_loading-icon').style.display = 'block';
    if (offset === undefined) {
      offset = 0;
    }

    let removecollapse = document.querySelector(".cSSearch_collapse");
    removecollapse.classList.remove("show");
    
    const request = {
      query: query,
      starttime:
        SearchTemplate.startDate !== null
          ? SearchTemplate.startDate
          : SearchTemplate.default_startDate,
      endtime:
        SearchTemplate.endDate !== null
          ? SearchTemplate.endDate
          : SearchTemplate.default_endDate,
      offset: offset ? offset : 0,
      limit: 5,
      estimatetotal:true,
      timezone:CommonTemplate.Timezone,
      timePhrase:  SearchTemplate.timephrase,
      table_name: SearchTemplate.selectTableName
     
    };

    ajax(
      "post",
      requestURI["search.search"],
      null,
      request,
      SearchTemplate.renderSearchData,
      SearchTemplate.renderErrorMessage,
      [offset]
    );
  },
  renderErrorMessage: (result) => {
    let nodata = null;
    const nosearchdata = document.querySelector("#bnfxSearchEvents");
    // document.getElementById('hunter_loading-icon').style.display = 'none';
    if (result.status === 200) {
      ShowErrorToastPopup(t("message.nodata"));
      nodata = (
        <NoContent
          id={"nodata"}
          title={t("error.searchmessage.title")}
          description={t("error.searchmessage.description")}
        />
      );
    } else {
      ShowErrorToastPopup(t("message.wrongquery"));
      nodata = (
        <NoContent
          id={"nodquerydata"}
          title={t("message.querytitle")}
          description={t("message.querydescription")}
        />
      );
    }
    CommonTemplate.ContainerAvailability(nosearchdata, nodata);
    const searchdate = document.querySelector("#bnfxseachdate");
    const searchdatedata = null;
    CommonTemplate.ContainerAvailability(searchdate, searchdatedata);

    const pagination = document.querySelector("#bnfxSearchPagePagination");
    const paginationdata = null;
    CommonTemplate.ContainerAvailability(pagination, paginationdata);
    document.getElementById('search-loading-icon').style.display = 'none';
    document.getElementById('search-sendbutton').style.display = 'block';
  },
  renderSearchData: (result, args) => {
    const resultdata = result?.data?.response?.result?.result;
    const status = result?.data?.response?.result?.status;
    const querytype = result?.data?.response?.querytype;
    const offset = args[0];
    const aggregated = result?.data?.response?.aggregated;
    const query = result?.data?.response?.query;
    let tablename = result?.data?.response?.querytype;
    setGlobalState("global_search_value", query);

    let total = aggregated
      ? resultdata?.length
      : result?.data?.response?.result?.total;
    if(offset===0){
      SearchTemplate.searchresulttotal=total
    }

    // document.getElementById('hunter_loading-icon').style.display = 'none';
    if (status === "failed" || status === undefined) {
      SearchTemplate.renderErrorMessage(result);
    } else {
      const searchdate = document.querySelector("#bnfxseachdate");
      const searchdatedata = (
        <SearchDateLayout
          total={SearchTemplate.searchresulttotal}
          startDate={
            SearchTemplate.startDate
              ? SearchTemplate.startDate
              : SearchTemplate.default_startDate
          }
          endDate={
            SearchTemplate.endDate
              ? SearchTemplate.endDate
              : SearchTemplate.default_endDate
          }
        />
      );
      CommonTemplate.ContainerAvailability(searchdate, searchdatedata);

      const aggregated = result?.data?.response?.aggregated;

      const container = document.querySelector("#bnfxSearchEvents");
      const data = (
        <SearchEvent
          searchData={resultdata}
          aggregated={aggregated}
          querytype={querytype}
          callback={() => {
            if (aggregated) {
              const searchtrend = document.querySelector("#searchtrendgraph");
              const searchtrenddata = null;
              CommonTemplate.ContainerAvailability(
                searchtrend,
                searchtrenddata
              );
              const pagination = document.querySelector(
                "#bnfxSearchPagePagination"
              );
              const paginationdata = null;
              CommonTemplate.ContainerAvailability(pagination, paginationdata);
            } else {
              SearchTemplate.renderPagination(
                SearchTemplate.searchresulttotal,
                offset
              );
            }
          }}
          tablename={tablename}
        />
      );

      CommonTemplate.ContainerAvailability(container, data);
      SearchTemplate.loadincidentdetailstrendgraph(query);
    }
    document.getElementById('search-loading-icon').style.display = 'none';
    document.getElementById('search-sendbutton').style.display = 'block';
  },

  renderPagination: (total, offset) => {
    if (offset > 0) {
      return;
    }
    const paginationData = {
      total: total,
      limit: 5,
      offset: offset ? offset : 0,
    };

    const container = document.querySelector("#bnfxSearchPagePagination");
    const data = (
      <Pagination
        pagination={paginationData}
        onClickFn={SearchTemplate.getSearchPagination}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },

  getSearchPagination: (offset) => {
    let query = getGlobalState("global_search_value");
    SearchTemplate.getSearchPage(offset, query);
  },

  applyClass: () => {
    const clicksearch = document.getElementById("searchicon");
    clicksearch.classList.add("active");
    // clicksearch.style.color = "red";
    clicksearch.innerHTML =
      '<span class="material-symbols-outlined navsearchicon">location_searching</span>';
    const searchPage = document.querySelector(".bxContent.SearchPage");
    searchPage.classList.add("active");
  },

  handleClick: () => {
    const queryStatement = "select * from detection";
    localStorage.setItem("setqueryStatement",queryStatement); 
    setGlobalState("global_search_value", queryStatement);
    localStorage.removeItem("setScoreTimestamp");
    SearchTemplate.renderSearchDatePicker();

    const clicksearch = document.getElementById("searchicon");
    if (clicksearch) {
      if (clicksearch.classList.contains("active")) {
        clicksearch.classList.remove("active");
        const title = document.getElementById("bnfxHeaderTitle").innerText;
        const navlink = document.querySelector(
          "a.bxNavlistLink.row[title = '" + title + "']"
        );
        window.location = navlink.getAttribute("href");
      } else {
        clicksearch.classList.add("active");
        // clicksearch.style.color = "red"; // Add CSS to change text color to red
        // // Change the innerHTML to include the desired HTML content
        clicksearch.innerHTML =
          '<span class="material-symbols-outlined navsearchicon">location_searching</span>';
      }
    }
  },

  createSearch: (formData, query) => {
    let selectedtenant = getGlobalState("global_selectedtenant");
    let tenant = null;
    if (!selectedtenant) {
      const tenants = CommonTemplate.getUserTenants();
      tenant = tenants[0];
    } else {
      tenant = selectedtenant.value;
    }

    let title = formData.title;
    let description = formData.description;
    let savedsearchtype = null;
    let pathname = window.location.pathname;
    if (pathname === "/searchpage") {
      savedsearchtype = "hunter";
    } else {
      savedsearchtype = "streams";
    }

    const request = {
      title: title,
      description: description,
      query: query,
      tenant: tenant,
      schedule: SearchTemplate.secheduleoption,
      type: savedsearchtype,
    };

    ajax(
      "post",
      requestURI["search.create"],
      null,
      request,
      SearchTemplate.loadSearchData,
      showError
    );
  },

  loadSearchData: (response) => {
    ToastPopup(t("message.added"));
    SearchTemplate.loadSearchList();
  },

  removeSearch: (data) => {
    let id = data._id;
    const request = {
      _id: id,
    };

    ajax(
      "post",
      requestURI["search.remove"],
      null,
      request,
      SearchTemplate.renderSearchDelete,
      showError
    );
  },

  renderSearchDelete: () => {
    ToastPopup(t("message.deleted"));
    SearchTemplate.loadSearchList();
  },

  loadSearchList: (offset) => {
    let savedsearchtype = null;
    const container = document?.querySelector("#bnfxsearchlayout");
    if(container){
    container.innerHTML = "";}

    if (offset === undefined) {
      offset = 0;
    }
    let pathname = window.location.pathname;
    if (pathname === "/searchpage") {
      savedsearchtype = "hunter";
    } else {
      savedsearchtype = "streams";
    }

    let limit = Math.floor(SearchTemplate.calculateSearchListHeight() / 76);
    const request = {
      searchtext: "",
      limit: limit,
      offset: offset ? offset : 0,
      type: savedsearchtype
    };

    ajax(
      "post",
      requestURI["search.list"],
      null,
      request,
      SearchTemplate.renderSearchList,
      SearchTemplate.searchListErrorMessage,
      [offset, limit, pathname]
    );
  },

  renderSearchList: (result, args) => {
    let resultdata = result?.data?.response?.result;
    let total = result?.data?.response?.total;
    let offset = args[0];
    let limit = args[1];
    let pathname = args[2];
    let resultlength = resultdata?.length;

    if (resultlength === 0) {
      let searchlistdata = null;
      if (pathname === "/searchpage") {
        searchlistdata = document.querySelector("#bnfxsearchlayout");
      } else {
        searchlistdata = document.querySelector("#bnfxstreamsavedsearchlayout");
      }
      const nodata = (
        <NoContent
          id={"nodata"}
          title={t("error.searchdatamessage.title")}
          description={t("error.searchdatamessage.description")}
        />
      );
      CommonTemplate.ContainerAvailability(searchlistdata, nodata);

      const searchlistpagination = document.querySelector(
        "#bnfxSearchListPagination"
      );
      const listpaginationdata = null;
      CommonTemplate.ContainerAvailability(
        searchlistpagination,
        listpaginationdata
      );
    } else {
      let container = null;
      if (pathname === "/searchpage") {
        container = document.querySelector("#bnfxsearchlayout");
      } else {
        container = document.querySelector("#bnfxstreamsavedsearchlayout");
      }
      const data = (
        <SearchDropdownLayout
          searchdata={resultdata}
          callback={() =>
            SearchTemplate.renderSearchListPagination(total, offset, limit)
          }
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  renderSearchListPagination: (total, offset, limit) => {
    if (offset > 0) {
      return;
    }
    const paginationData = {
      total: total,
      limit: limit,
      offset: offset ? offset : 0,
    };

    const container = document.querySelector("#bnfxSearchListPagination");
    const data = (
      <Pagination
        pagination={paginationData}
        onClickFn={SearchTemplate.getSearchListPagination}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },

  getSearchListPagination: (offset) => {
    SearchTemplate.loadSearchList(offset);
  },

  handleedit: (data) => {
    let query = data.query;

    CommonTemplate.getTenants();
    SearchTemplate.setSearchQueryId(data._id);
    setGlobalState("isSearchEdit", true);
    document.getElementById("bnfx_globalsearchtitle").value = data.title;
    document.getElementById("bnfx_globalsearchdescription").value =
      data.description;
    const result = { value: data.tenant, label: data.tenant };
    setGlobalState("global_selectedtenant", result);
    setGlobalState("global_edit_query", query);

    const schedule= {value: data.schedule.toLowerCase(),label: data.schedule.replace("@", "").toUpperCase()}
    setGlobalState("selected_schedule",schedule );
  },

  updateSearch: (formData, query) => {
    let selectedtenant = getGlobalState("global_selectedtenant");
    let id = SearchTemplate.searchqueryid;
    let tenant = selectedtenant;
    let title = formData.title;
    let description = formData.description;
    let savedsearchtype = null;
    let pathname = window.location.pathname;
    if (pathname === "/searchpage") {
      savedsearchtype = "hunter";
    } else {
      savedsearchtype = "streams";
    }
    const request = {
      _id: id,
      title: title,
      description: description,
      query: query,
      tenant: tenant.value,
      schedule: SearchTemplate.secheduleoption,
      type: savedsearchtype,
    };

    ajax(
      "post",
      requestURI["search.update"],
      null,
      request,
      SearchTemplate.renderSearchUpdate,
      showError
    );
  },

  renderSearchUpdate: () => {
    ToastPopup(t("message.updated"));
    SearchTemplate.loadSearchList();
  },

  clickOnView: (query) => {
    let pathname = window.location.pathname;
    if (pathname === "/searchpage") {
      setGlobalState("global_search_value", query);
      const pagination = document.querySelector(
        "#bnfxSearchPagePagination"
      );
      const paginationdata = null;
      CommonTemplate.ContainerAvailability(pagination, paginationdata);
      SearchTemplate.getSearchPage(null, query);
      let removecollapse = document.querySelector(".cSSearch_collapse");
      removecollapse.classList.remove("show");
    } else {
      let extractedValue = SearchTemplate.extractValueFromQuery(query);
      setGlobalState("bnfx_global_stream_detction_serachbar", extractedValue);
      Stream.executesavedearchquery(Stream.selected_stream?._id,extractedValue);
      let removecollapse = document.querySelector(".streamsavedsearch");
      removecollapse.classList.remove("show");
    }

    ToastPopup(t("message.execute"));
  },
  extractValueFromQuery: (query) => {
    const match = query.match(/where\s+(.+)/i);
    return match ? match[1] : null;
  },

  formatTimestamp: (timestamp) => {
    const date = new Date(timestamp);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString(undefined, options);
  },

  searchListErrorMessage: (result, args) => {
    let pathname = args[2];
    ShowErrorToastPopup(t("message.nosearchdata"));
    let nosearchdata = null;
    if (pathname === "/searchpage") {
      nosearchdata = document.querySelector("#bnfxsearchlayout");
    } else {
      nosearchdata = document.querySelector("#bnfxstreamsavedsearchlayout");
    }

    const nodata = (
      <NoContent
        id={"nosearchlist"}
        title={t("error.searchdatamessage.title")}
        description={t("error.searchdatamessage.description")}
      />
    );
    CommonTemplate.ContainerAvailability(nosearchdata, nodata);

    const searchlistpagination = document.querySelector(
      "#bnfxSearchListPagination"
    );
    const listpaginationdata = null;
    CommonTemplate.ContainerAvailability(
      searchlistpagination,
      listpaginationdata
    );
  },

  calculateSearchListHeight: () => {
    var tabheight = document.querySelector(".pageheight").clientHeight;
    var paginationheight = 34;
    var tabheaderheight =
      document.querySelector(".how_search_text").clientHeight;
    var totalheight = tabheight - (paginationheight + tabheaderheight);
    return totalheight;
  },

  handleToggle: () => {
    const isExpanded = getGlobalState("search_collapsed");
    setGlobalState("search_collapsed", !isExpanded);
    SearchTemplate.loadSearchList();
  },

  loadrowonclick: (data, querytype, key) => {
    let detectiondata = null;
    if (querytype === "detection") {
      if (key === "entity") {
        detectiondata = {
          entity: data.entity,
          entitytype: data.entitytype,
          risk_score: data.score,
          type: "entity",
          streamid: data.streamid,
          tenant: data.tenant,
        };
      } else if (key === "streamname") {
        detectiondata = { id: data.streamid, type: "stream" };
      } else {
        detectiondata = {
          detectionid: data.detectionid,
          name: data.name ? data.name : "no title",
          type: "detection",
          streamid: data.streamid,
          tenant: data.tenant,
        };
      }
    } else if (querytype === "entityscoring") {
      if (key === "detectionname") {
        detectiondata = {
          detectionid: data.detectionid,
          name: data.detectionname ? data.detectionname : "no title",
          type: "detection",
          streamid: data.streamid,
          tenant: data.tenant,
        };
      } else if (key === "streamname") {
        detectiondata = { id: data.streamid, type: "stream" };
      } else {
        detectiondata = {
          entity: data.entity,
          entitytype: data.entitytype,
          risk_score: data.score,
          type: "entity",
          streamid: data.streamid,
          tenant: data.tenant,
        };
      }
    } else if (querytype === "incidentdetails") {
      if (key === "_detectionname") {
        detectiondata = {
          detectionid: data.detectionid,
          name: data.name ? data.name : "no title",
          type: "detection",
          streamid: data.streamid,
          tenant: data.tenant,
        };
      } else if (key === "entity") {
        detectiondata = {
          entity: data.entity,
          entitytype: data.entitytype,
          risk_score: data.score,
          type: "entity",
          streamid: data.streamid,
          tenant: data.tenant,
        };
      } else if (key === "_streamname") {
        detectiondata = { id: data.streamid, type: "stream" };
      } else {
        detectiondata=data
      }
    }
    let starttime =
      SearchTemplate.startDate !== null
        ? SearchTemplate.startDate
        : SearchTemplate.default_startDate;

    let endtime =
      SearchTemplate.endDate !== null
        ? SearchTemplate.endDate
        : SearchTemplate.default_endDate;

    // Format the dates in the desired format
    // let formattedStartTime = CommonTemplate.timeconversion(starttime);
    // let formattedEndTime = CommonTemplate.timeconversion(endtime);

    // const timephrase= "Last "+Stream.selected_days+" Days"
    const timephrase=SearchTemplate.timephrase;
    PanelScript.loadrowOnclick(detectiondata,starttime,endtime,timephrase);
  },

  loadincidentdetailstrendgraph: (type) => {
    CommonTemplate.renderLoadingWaveIcon("#searchtrendgraph")
    const request = {
      starttime:
        SearchTemplate.startDate !== null
          ? SearchTemplate.startDate
          : SearchTemplate.default_startDate,
      endtime:
        SearchTemplate.endDate !== null
          ? SearchTemplate.endDate
          : SearchTemplate.default_endDate,
      query: type,
      timezone:CommonTemplate.Timezone,
      timePhrase:  SearchTemplate.timephrase,
      table_name: SearchTemplate.selectTableName
    };
    ajax(
      "post",
      requestURI["search.trend.chart"],
      null,
      request,
      SearchTemplate.rendersearchtrendgraph,
      SearchTemplate.redertrendgrapherror
    );
  },
  redertrendgrapherror:(result)=>{

    const container = document.querySelector("#searchtrendgraph");
    let data=null
    CommonTemplate.ContainerAvailability(container, data);
  },
  rendersearchtrendgraph: (result) => {
    let status=result.data.status
    if(status==="FAILED"){
      SearchTemplate.redertrendgrapherror(result)
    }
    else{
    let output = result?.data?.response?.result;
    let series = output?.data;
    let categories = output?.categories;
    const allZeros = series?.every((value) => value === 0);
    let trendchart = null;
    const container = document.querySelector("#searchtrendgraph");
    if (allZeros) {
      trendchart = null;
    } else {
      trendchart = (
        <TrendChart
          series={series}
          categories={categories}
          container={"searchtrendgraph"}
          height={120}
          tickInterval={categories?.length > 10 ? 3 : 2}
        />
      );
    }
    CommonTemplate.ContainerAvailability(container, trendchart);}
  },
  getTenantlist: () => {
    SearchTemplate.getclearform();
    setGlobalState("global_edit_query", null);
    CommonTemplate.getTenants();
    
  },

  getclearform: () => {
    document.getElementById("bnfx_globalsearchtitle").value = "";
    document.getElementById("bnfx_globalsearchdescription").value = "";
    setGlobalState("selected_schedule",null)
    setGlobalState("global_selectedtenant", null);
    setGlobalState("isSearchEdit", false);
  },
  Onspecifictableonclick: (data) => {
    // const streamElements = document.querySelectorAll(".searchtablelist");
    // streamElements.forEach((element) => {
    //   element.classList.remove("active");
    // });
    // let table = document.getElementById("bnfxArtifactactor" + data);
    // table.classList.add("active");
    SearchTemplate.setTableName(data.value)
    let LLMSTABLE = process.env.REACT_APP_LLM_STABLE;
    let query =null
    if(LLMSTABLE==="true"){
     query = "give all data for  "+data.value+" table"}
     else{
      query = "select * from  "+data.value
     }
    setGlobalState("global_search_value", query);
    const pagination = document.querySelector(
      "#bnfxSearchPagePagination"
    );
    const paginationdata = null;
    CommonTemplate.ContainerAvailability(pagination, paginationdata);
    SearchTemplate.getSearchPage(null, query);
  },
  onChangeSql :(value) => {
    SearchTemplate.searchSql = value;
    setGlobalState("global_search_value", value);
  },
  handleDoubleClick: (key, value, tablename) => {
    let detectionSql = null;

    if (key.includes("set")) {
      const keyParts = key.split(".");
      keyParts.pop();

      detectionSql = ` select * from ${tablename} where ${keyParts.join(
        "."
      )} @> '["${value}"]'::jsonb`;
    } else if (key && key.startsWith("message:")) {
      const keyParts = key.split(":");
      detectionSql = `SELECT * FROM ${tablename} WHERE ${keyParts[0]} ->> '${keyParts[1]}' = '${value}'`;
    } else {
      detectionSql = `select * from ${tablename} where ${key} in ('${value}')`;
    }
    setGlobalState("global_search_value", detectionSql);
    // SearchTemplate.getSearchPage(null, detectionSql);
  },

  searchresultdownload: () => {
    const request = {
      query: SearchTemplate.searchSql,
      starttime:
        SearchTemplate.startDate !== null
          ? SearchTemplate.startDate
          : SearchTemplate.default_startDate,
      endtime:
        SearchTemplate.endDate !== null
          ? SearchTemplate.endDate
          : SearchTemplate.default_endDate,
      offset: 0,
      limit: 10,
      download: true,
      fileprefix: document.querySelector("#prefixInput").value,
      timezone:CommonTemplate.Timezone,
      timePhrase:  SearchTemplate.timephrase
    };

    ajax(
      "post",
      requestURI["search.search"],
      null,
      request,
      SearchTemplate.renderDownloadeddata,
      SearchTemplate.renderDownloadeerror
    );
  },
  renderDownloadeddata: (result) => {
    // let path = result.data.response.result.result.path;
    let refinepath = result.data.response.result.result.path;
    const parts = refinepath.split("/");
    const filename = parts.pop(); // Extract the last part as filename
    const directory = parts.pop(); // Rejoin the remaining parts as directory
    let path = `<span class="p-2 text-white">Download <p class="textPrimary">${filename}</p> </span><small>You could also download later from available exports from header</small> <button class="textPrimary" data-bs-dismiss="modal">click here</button>`;
    let conatiner = document.querySelector("#bnfxdownloadedpath");
    let closeconnection = document.querySelector("#bnfxprefixform");
    closeconnection?.classList?.add("d-none");
    let newconnection = document.querySelector(".downloadresult");
    newconnection?.classList?.add("d-none");
    conatiner.innerHTML = path;

    const clickHandler = () => {
      DownloadsScript.downloadapicall(directory, filename);
      conatiner.removeEventListener("click", clickHandler);
      conatiner.innerHTML = "";
    };
    conatiner.addEventListener("click", clickHandler);
  },
  renderDownloadeerror: (result) => {
    ShowErrorToastPopup(t("message.nodata"));
  },
  handleScheduleChange: (option) => {
    SearchTemplate.secheduleoption = option?.value;
    setGlobalState("selected_schedule", option|| { value: '', label: '' });
  },
  setTableName: (selectTableName) => {
    SearchTemplate.selectTableName = selectTableName;
  },
  redirectToPreviousPage:()=>{
    window.history.back();   
  }
};
