import { Organization } from "./OrganizationScript";
import Select from "react-select";
import {
  getGlobalState,
  setGlobalState,
  useGlobalState,
} from "../../components/common/GlobalVariables";
import classNames from "classnames";
import { useState } from "react";
import { t } from "i18next";
import ShowErrorToastPopup from "../../components/common/ShowErrorToastPopup";
import QRCode from 'qrcode';
import LoadingDataEffect from "../../components/common/LoadingWidget/LoadingDataEffect";

function AddUser() {
  const [TenantOptions] = useGlobalState("TenantOptions");
  const [RoleOptions] = useGlobalState("RoleOptions");
  const [groupOptions] = useGlobalState("groupOptions");

  const [isEdit] = useGlobalState("isEdit");
  const [_id] = useGlobalState("_id");
  let statusoptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "DEPRECATED", label: "DEPRECATED" },
  ];
  const mfaTypes = [
    { value: "otp", label: "One Time Password" },
    { value: "google", label: "Google Authenticator" }
  ]
  const [selectedStatus, setSelectedStatus] = useGlobalState("selectedStatus");
  const handlestatusChange = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
  };
  const [selectedGroups, setSelectedGroups] = useGlobalState("selectedGroups");
  const [selectedRoles, setSelectedRoles] = useGlobalState("selectedRoles");
  const [selectedUserTenants, setSelectedTenants] = useGlobalState("selectedUserTenants");
  const [mfaData, setMfaData] = useGlobalState("mfaData");
  const [qrCodeData, setQrCodeData] = useState("");
  const [generatedSecret, setGeneratedSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  
  const handleRoleSelectChange = (selectedOptions) => {
    setSelectedRoles(selectedOptions);
  };

  const handleTenantSelectChange = (selectedOptions) => {
    setSelectedTenants(selectedOptions);
  };

  const handleGroupSelectChange = (selectedOptions) => {
    setSelectedGroups(selectedOptions);
  };

  const handleMfaChange = async (event, selectedOptions) => {
    let name = event.target.name;
    let val = event.target.value;
    if (name === "user_mfaenabled") {
      val = event.target.checked;
    } else if (name === "user_mfatype") {
      // if google then generate the code
      if (val === "google" && !mfaData.user_mfasecret) {
        setMfaData({...mfaData, [name]: val});
        setIsLoading(true);
        const secretResponse = await Organization.generateMfaSecret(val);
        setIsLoading(false);
        setGeneratedSecret(secretResponse?.response?.mfasecret);
        if (secretResponse?.response?.mfasecret) {
          const qrCodeUrl = `otpauth://totp/Binaryflux:${"Binaryflux"}?secret=${secretResponse?.response?.mfasecret}&issuer=Binaryflux`;
          QRCode.toDataURL(qrCodeUrl)
                .then(url => {
                    setQrCodeData(url);
                });
        }
      }
    }
    setMfaData({...mfaData, [name]: val});
  };

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleInputChange = () => {
    if (!isEdit){
    const requiredFields = [
      "user_firstname",
      "user_lastname",
      "user_username",
      "user_password",
      "user_email",
    ];
    let areAllFieldsFilled = true;

    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (!field || field.value.trim() === "") {
        areAllFieldsFilled = false;
        field.classList.add("border-danger");
      } else {
        field.classList.remove("border-danger");
      }
    });

    setIsSaveEnabled(areAllFieldsFilled);}
    else{
      setIsSaveEnabled(true)
    }
  };
  const clearfrom = () => {
    document.getElementById("user_firstname").value = "";
    document.getElementById("user_lastname").value = "";
    document.getElementById("user_username").value = "";
    document.getElementById("user_password").value = "";
    document.getElementById("user_email").value = "";

    setSelectedTenants([]);
    setSelectedRoles([]);
    setSelectedGroups([]);
    setMfaData({});
    setGlobalState("isEdit", false);
    setGeneratedSecret("");
    setQrCodeData("");
    
    const requiredFields = [
      "user_firstname",
      "user_lastname",
      "user_username",
      "user_password",
      "user_email",
    ];
    requiredFields.forEach((fieldId) => {
      const field = document.getElementById(fieldId);

      field.classList.remove("border-danger");
    });
  };
  const handleFormSubmit = (e) => {

    let selectedTenants=null
    if(selectedUserTenants?.[0]?.value===undefined){
      selectedTenants=  [{
        value: selectedUserTenants?.[0],
        label: selectedUserTenants?.[0],
    }]}
    else{
      selectedTenants=selectedUserTenants

    }
 

    const formData = {
      Firstname: document.getElementById("user_firstname").value,
      Lastname: document.getElementById("user_lastname").value,
      Username: document.getElementById("user_username").value,
      password: document.getElementById("user_password").value,
      emailaddress: document.getElementById("user_email").value,
      userstatus: selectedStatus,
      Tenants: selectedTenants,
      roles: selectedRoles,
      groups: selectedGroups,
      mfaData: mfaData,
      generatedSecret: generatedSecret
    };

      const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
      const emailInput = document.getElementById("user_email");
      const email = emailInput.value;
      const isEmailValid = emailRegex.test(email);

      if (!isEmailValid) {
        ShowErrorToastPopup(t("email.error.message"));
        document.getElementById("user_email").value = "";
        return;
      } else {
        if (isEdit) {
          Organization.update_user(formData, "securityuser", _id);
        } else {
          Organization.addUser(formData);
        }
        clearfrom();
      }


    
  };

  let singletenant = getGlobalState("singletenant");
  let super_admin=getGlobalState("super_admin")
  let tenantoption = null;
  if (singletenant  ) {
    tenantoption = null;

  } else {
    tenantoption = (
      <div className="col-sm-6">
        <div className="mb-2">
          <label>{t("organization.tenants")}</label>
          <Select
            placeholder="Please select tenants"
            id="tenants"
            className="form-control"
            isMulti
            options={TenantOptions}
            value={selectedUserTenants}
            onChange={handleTenantSelectChange}
          ></Select>
          <small className="d-block text-end bxFontSize">{t("required")}</small>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="bxOffcanvasAddOrg AddUser offcanvas bg-dark offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddUser"
        aria-labelledby="offcanvasAddUserLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-white" id="offcanvasAddUserLabel">
            {isEdit? t("organization.update.user.header.name"):t("organization.add.user.header.name")}
          </h5>
        </div>
        <div className="offcanvas-body p-0">
          <div className="bxAddUserForm p-4">
            <form action="">
              <div className="row">
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="user_firstname" className="form-label">
                      {t("organization.add.user.firstname")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_firstname"
                      placeholder="Please enter first name"
                      autoComplete="off"
                      name="Firstname"
                      required
                      onChange={handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="user_lastname" className="form-label">
                      {t("organization.add.user.lastname")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_lastname"
                      placeholder="Please enter last name"
                      autoComplete="off"
                      name="Lastname"
                      required
                      onChange={handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="user_username" className="form-label">
                      {t("organization.add.user.username")}
                    </label>
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "low-opacity": isEdit,
                      })}
                      id="user_username"
                      placeholder="Please enter username"
                      autoComplete="off"
                      name="User_Username"
                      required
                      readOnly={isEdit}
                      onChange={handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="user_email" className="form-label">
                      {t("organization.email.address")}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="user_email"
                      placeholder="eg.binary@gmail.com"
                      autoComplete="off"
                      name="emailaddress"
                      required
                      onChange={handleInputChange}
                    />
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label className="form-label">
                      {t("status")}
                    </label>
                    <Select
                      placeholder="Please select criticality"
                      id="criticality"
                      aria-label="Default select example"
                      name="criticality"
                      options={statusoptions}
                      value={selectedStatus}
                      onChange={handlestatusChange}
                    ></Select>
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label htmlFor="user_password" className="form-label">
                      {t("password")}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="user_password"
                      placeholder="Please enter new password"
                      autoComplete="off"
                      name="User_Password"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
            {!super_admin?(<>
                {tenantoption}
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label >{t("organization.roles")} </label>
                    <Select
                      placeholder="Please select roles"
                      id="roles"
                      className="form-control"
                      isMulti
                      options={RoleOptions}
                      value={selectedRoles}
                      onChange={handleRoleSelectChange}
                      onFocus={Organization.getfilteredrole}
                    ></Select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mb-2">
                    <label >{t("organization.groups")} </label>
                    <Select
                      placeholder="Please select groups"
                      id="groups"
                      className="form-control"
                      isMulti
                      options={groupOptions}
                      value={selectedGroups}
                      onChange={handleGroupSelectChange}
                      onFocus={Organization.getfilteredgroup}
                    ></Select>
                  </div>
                </div></>):null}
                <div className="col-sm-12">
                  <div className="mb-2">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id="user_mfaenabled"
                      name="user_mfaenabled"
                      checked={mfaData.user_mfaenabled}
                      onChange={(e) => handleMfaChange(e, "user_mfaenabled")}
                    />
                    <label htmlFor="user_mfaenabled" className="form-label ps-1">
                      {t("organization.add.user.enable.mfa")}{" "}
                    </label>
                  </div>
                </div>
                {mfaData.user_mfaenabled && <div className="col-sm-12">
                  <div className="mb-2">
                    <label className="form-label">
                      {t("organization.add.user.enable.mfa.types")}
                    </label>
                    <Select
                      id="user_mfatype"
                      name="user_mfatype"
                      options={mfaTypes}
                      value={mfaTypes?.find(
                        (option) => option.value === mfaData.user_mfatype
                      )}
                      onChange={(option) => handleMfaChange({ target: { name: "user_mfatype", value: option.value } }, "user_mfatype")}
                    ></Select>
                    <small className="d-block text-end bxFontSize">
                      {t("required")}
                    </small>
                  </div>
                </div>}
                <div className="col-sm-12">
                  {isLoading && <LoadingDataEffect />}
                  {mfaData.user_mfaenabled && mfaData.user_mfatype == "google" && qrCodeData != "" 
                    && <img width="100px" src={qrCodeData} alt="QR Code for MFA" />}

                  {mfaData.user_mfaenabled && mfaData.user_mfatype == "google" && generatedSecret != "" 
                    && <div><label>Or Use Setup Key:</label> {generatedSecret}</div>}

                  {mfaData.user_mfaenabled && mfaData.user_mfatype == "otp"  
                    && <div><label>{t("organization.add.user.mfa.otp.info")}</label></div>}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="offcanvas-footer py-2 px-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="text-white me-3"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                clearfrom();
                Organization.removeClass();
              }}
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                handleFormSubmit();
                Organization.removeClass();
              }}
              disabled={!isEdit && !isSaveEnabled}
            >
              {t("name.save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
