import React from "react";
import jwt from "jwt-decode";
import moment from "moment";
import { t } from "i18next";
import { DownloadsScript } from "../../../pages/download/DownloadsScript";

function IncidenceActivitesChat({ data, onExpand }) {
  let result = data;
  const token = localStorage.getItem("token");
  const userdetails = jwt(token);

  const handleDownload = (actioncomment) => {
    // Extract the URL of the attachment from actioncomment
    const linkMatch = actioncomment.match(/href="([^"]+)"/);

    if (linkMatch) {
      const filePath = linkMatch[1]; // Full URL to the attachment
      // Extract the file name after '/upload-attachments/'
      const fileName = filePath.split("/upload-attachments/")[1];

      DownloadsScript.downloadapicall("upload-attachments", fileName);
    }
  };

  if (result !== undefined) {
    const reversedResult = [...result].reverse();
    return (
      <>
        {result &&
          reversedResult.map((entry, index) => {
            const isExpandable = entry.actioncomment.length > 500;
            // Check if actioncomment contains an <a> tag with class 'distinct-class'
            const hasDownloadLink = /<a[^>]*class="distinct-class"[^>]*>/i.test(
              entry.actioncomment
            );

            return (
              <div
                className={
                  entry.actiontakenby === userdetails.username
                    ? "tl-container tl-right same-sender"
                    : "tl-container tl-right"
                }
                key={index}
              >
                <small className="date">
                  {moment(new Date(entry.actiontime)).format("DD MMM hh:mm")}
                </small>

                <b className="text-white d-block">
                  {entry.actiontakenby === userdetails.username
                    ? "YOU"
                    : entry.actiontakenby}
                </b>
                <div className="tl-content mt-2">
                  <div className="bxBgChat mb-0">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: hasDownloadLink
                          ? entry.actioncomment
                          : isExpandable
                          ? `${entry.actioncomment.slice(0, 500)}...`
                          : entry.actioncomment,
                      }}
                    />
                    {isExpandable && !hasDownloadLink && (
                      <span
                        className="material-symbols-outlined evidence-expand-icon"
                        title="Details"
                        onClick={() => onExpand(entry.actioncomment)}
                      >
                        open_in_full
                      </span>
                    )}
                    {hasDownloadLink && (
                      <span
                        className="material-symbols-outlined download-icon"
                        title="Download Attachment"
                        onClick={() => handleDownload(entry.actioncomment)}
                      >
                        downloading
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  } else {
    return (
      <div className="bnfx_paginationscroll_nodata">
        <b>{t("incident.activities.scroll")}</b>
      </div>
    );
  }
}

export default IncidenceActivitesChat;
