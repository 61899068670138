import moment from "moment";
import { JSONTree } from "react-json-tree";
import ToastPopup from "../ToastPopup";
import { t } from "i18next";
import { Automation } from "./AutomationScript";

function AutomationDetailItem({ taskdata }) {
  let data = taskdata.data.response.result[0].taskdetails[0];
  let status = data.status;
  let output = data.output;
  let statusname = Automation.automationTaskStatusIcon(status);
  return (
    <>
      <div className="p-3 bnfxTaskData">
        <div className="card dark-bg" id={data.executionid + data.taskname}>
          <div className="card-body cm_border">
            <div className="d-flex align-items-center justify-content-between">
              <div className="Custom_rightArea_head">
                <ul className="p-0 m-0 bxOC_list d-flex align-items-center">
                  <li className="BXf-12 text-white text-capitalize">
                    <b>
                      {t("automation.taskname")}
                      {data.taskname}
                    </b>
                  </li>

                  <li className="BXf-12 text-white">{statusname}</li>
                </ul>
                <ul className="p-0 m-0 mt-2 bxOC_list d-flex align-items-center">
                  <li className="BXf-12">
                    {t("task.starttime")}
                    <span>
                      {moment(new Date(data.starttime)).format("DD MMM YYYY hh:mm A")}
                    </span>
                  </li>
                  <li className="BXf-12">
                    {t("task.endtime")}
                    <span>
                      {moment(new Date(data.endtime)).format("DD MMM YYYY hh:mm A")}
                    </span>
                  </li>
                </ul>
              </div>
              <button
                    type="button"
                    className="copy_text"
                    data-bs-toggle="popover"
                    title="Copy"
                    onClick={(e) => {
                      Automation.copyTaskData(
                        e,
                        "bnfxTaskData",
                        null,
                        output
                      );
                      ToastPopup(t("copy.data.toast"));
                    }}
                  >
                    <p className="m-0 text-info d-flex align-items-center ">
                      <span className="material-symbols-outlined BXf-16 me-2">
                      content_copy
                      </span>
                    </p>
                  </button>
             
            </div>
          </div>

          <div className="card-body">
            <div id="wrapper">
              <ul id="tree1" className="tree ">
                {typeof output === "object" ? (
                  <JSONTree
                    data={output}
                    theme="bright"
                    invertTheme={false}
                    hideRoot={true} //this line is use to hide root name from jsontree
                    getItemString={(type, data, itemType, itemString) => ""} //this line is use to remove '{}keys count' for each node
                  />
                ) : (
                  <p className="al_border_leftSide text-uppercase">
                    {output  ? output : "No output"}
                  </p>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutomationDetailItem;
