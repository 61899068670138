import { useEffect } from "react";
import { LEFT_PANEL_SECTIONS } from "../contentConstants";
import { Content } from "../ContentScript";
let isLoaded = false;

function ContentListLeftPanel({
  selectedLeftPanelItemName,
  onLeftPanelItemClick,
}) {
  useEffect(() => {
    if (!isLoaded) {
      isLoaded = true;
      document.getElementById("navArrow0").click();
    }
  });
  return (
    <div className="col-md-2">
      <div id="leftside-menu-container" className="setting_Left_SideBar h-100">
        <div className="leftbar-area">
          <ul className="side-nav p-0 ">
            {LEFT_PANEL_SECTIONS.map((item, index) => {
              return (
                <li key={index} className="side-nav-item">
                  <a
                    className={
                      selectedLeftPanelItemName === item.name
                        ? "menu-link side-nav-link active"
                        : "side-nav-link menu-link"
                    }
                    onClick={() => onLeftPanelItemClick(item.name)}
                  >
                    <span
                      className={`material-symbols-outlined me-3 content_icon_colored_${item.icon}`}
                    >
                      {item.icon}
                    </span>
                    {item.title}
                    {item?.components?.length > 0 && (
                      <span
                        id={"navArrow" + index}
                        className="arrow"
                        onClick={(event) => {
                          Content.handleMenuClick(event, index);
                        }}
                      ></span>
                    )}
                  </a>

                  <ul className="submenu ps-2 my-2" style={{ display: "none" }}>
                    {item?.components?.map((component, index) => {
                      return (
                        <li key={index} className="side-nav-item">
                          <a
                            className={
                              selectedLeftPanelItemName === component.name
                                ? "side-nav-link active"
                                : "side-nav-link"
                            }
                            onClick={() => onLeftPanelItemClick(component.name)}
                          >
                            <span
                              className={`material-symbols-outlined me-3 content_icon_colored_${component.icon}`}
                            >
                              {component.icon}
                            </span>
                            {component.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContentListLeftPanel;
