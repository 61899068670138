import React from "react";

function RawDataLayout() {
  return (
    <>

       
       

        <div className="mb-2" id="bnfxRaweventsloadSearch">{/* here we replace detection search */}</div>

        <div className="raweventstrendchartgraph">

        {/* render raweventtrend graph */}
        </div>
      
        <div className="mt-2" id="bnfxStreamRawdata">
          {/* render rawevent */}
        </div>
        <div id="bnfxRawDataPagination">

        </div>
    </>
  );
}

export default RawDataLayout;
