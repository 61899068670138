import { useState } from "react";
import { Stream } from "../StreamScript";
import ToastPopup from "../../../components/common/ToastPopup";
import { t } from "i18next";
import { useGlobalState } from "../../../components/common/GlobalVariables";
import LoadingBar from "../../../components/common/TopLoadingBar";

const StreamListItem = (props) => {
  let stream_component = null;
  const [activeStreamId, setActiveStreamId] = useState(null);

  const handleStreamClick = (streamId) => {
    // Remove the "active" class from all stream elements inside the container
    const streamElements = document.querySelectorAll(".row .card");
    streamElements.forEach((element) => {
      element.classList.remove("active");
    });

    // Apply the "active" class to the clicked stream
    const clickedEnabledStreamElement = document.getElementById(
      `enabled-stream-form${streamId}`
    );
    if (clickedEnabledStreamElement) {
      clickedEnabledStreamElement.classList.add("active");
    }
    const clickedAvailableStreamElement = document.getElementById(
      `available-stream-form${streamId}`
    );
    if (clickedAvailableStreamElement) {
      clickedAvailableStreamElement.classList.add("active");
    }

    setActiveStreamId(streamId);
  };
  const handleStreamLoad = async () => {
    // Your existing click logic here
    Stream.loadStream(props.stream);
  };
  console.log(props.enabled)
  if (props.enabled) {
    stream_component = (
      <div className="row m-0">
        <div className="bxStreamList_sec p-0">
          <span
            className={`card ${props.stream?._id === activeStreamId ? "active" : ""
              }`}
            id={`enabled-stream-form${props.stream?._id}`}
            key={props.stream?._id}
            onClick={() => handleStreamClick(props.stream?._id)}
          >
            <LoadingBar onClick={handleStreamLoad}>
              <div
                className="bxStream_link"

              >
                <span
                  className={
                    "bxIcon_link " + props?.stream?.datasource?.toLowerCase()
                  }
                >
                  {props.stream?.title.charAt(0)}
                </span>
                <div
                  className={
                    "bxStream_linkContent stream-link stream_list_item_" +
                    props.stream?._id
                  }
                  identifier={props.stream?._id}
                >
                  <h3 className="bxStreamTitle_link"  title={props.stream?.title} >{props.stream?.title}</h3>

                  <p className="bxStreamDiscription_link" title={props.stream?.description}>
                    {props.stream?.description}
                  </p>
                </div>
              </div>
            </LoadingBar>
          </span>
          <div className="bxStreamAction_link">
          <div className={props.stream?.title === 'Employee Directory' ? 'bxStreamActionBtn_link EMPDirectory' : 'bxStreamActionBtn_link'} >
              <button
                type="button"
                title="Copy"
                className="btn btnSteram uncollapsedview"
                id="data-to-copy"
                onClick={(e) => {
                  Stream.copyDetection(
                    e,
                    "stream_list_item_" + props.stream?._id,
                    true
                  );
                }}
              >
                <span className="material-symbols-outlined">
                content_copy
                </span>{" "}
                {t("copytitle")}
              </button>
              <button
                type="button"
                title="Load Stream"
                className="btn btnSteram collapsedview d-none"
                id="data-to-copy"
                onClick={() => {
                  handleStreamClick(props.stream?._id)
                  Stream.loadDetailsLayout(props.stream);
                  ToastPopup(t("stream.loaded.toast"));
                }}
              >
                <span className="material-symbols-outlined">
                list
                </span>{" "}
                {t("copytitle")}
              </button>

              {props.stream?.title!=='Employee Directory'&&<button
                type="button"
                title="View"
                className="btn btnSteram"
                data-bs-toggle="offcanvas"
                data-bs-target="#bxOffcanvasPanel"
                aria-controls="bxOffcanvasPanel"
                onClick={() => {
                  Stream.loadActionDetails(props.stream, props.enabled);
                }}
              >
                <span className="material-symbols-outlined">
                visibility
                </span>{" "}
                {t("stream.view")}
              </button>}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    stream_component = (
      <div className="row m-0">
        <div
          className="bxStreamList_sec p-0"
          onClick={() => Stream.selectAvailable(props.stream)}
        >
          <span
            className={`card ${props.stream?._id === activeStreamId ? "active" : ""
              }`}
            id={`available-stream-form${props.stream?._id}`}
            key={props.stream._id}
            onClick={() => handleStreamClick(props.stream?._id)}
          >
            <div className="bxStream_link">
              <span
                className={
                  "bxIcon_link " + props.stream.datasource.toLowerCase()
                }
              >
                {props.stream.title.charAt(0)}
              </span>
              <div
                className={
                  "bxStream_linkContent stream-link stream_list_item_" +
                  props.stream?._id
                }
                identifier={props.stream?._id}
              >
                <h3 className="bxStreamTitle_link">{props.stream?.title}</h3>

                <p className="bxStreamDiscription_link">
                  {props.stream?.description}
                </p>
              </div>
            </div>
          </span>
          <div className="bxStreamAction_link">
            <div className="bxStreamActionBtn_link">
              <button
                type="button"
                className="btn btnSteram"
                id="data-to-copy"
                onClick={(e) => {
                  Stream.copyDetection(
                    e,
                    "stream_list_item_" + props.stream?._id,
                    true
                  );
                  ToastPopup(t("copy.data.toast"));
                }}
              >
                <span className="material-symbols-outlined">
                content_copy
                </span>{" "}
                {t("copytitle")}
              </button>
              {props.stream?.title!=='Employee Directory'&& <button
                type="button"
                className="btn btnSteram"
                data-bs-toggle="offcanvas"
                data-bs-target="#bxOffcanvasPanel"
                aria-controls={`bxOffcanvasPanel${props.stream?._id}`}
                onClick={() => {
                  Stream.loadActionDetails(props.stream, props.enabled);
                }}
              >
                <span className="material-symbols-outlined">
                visibility
                </span>{" "}
                {t("stream.view")}
              </button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <>{stream_component}</>;
};

export default StreamListItem;