import React, { useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";

const BarChart = (props) => {
  const chartContainer = useRef(null);
  let colors = null;
  let container=props.container;
  let widgetid=props.id;
  let column=props.column
  let series=props.series;
  let categories=props.categories;
  let height=props.height;
  let layout=props.layout;
  let hidex=props.hidex;
  const onclick_param=props.onclick_param;
  const label=props.label;
  const columnmap=props.columnmap;
  
  const handleNameClick = (name,point) => {
    let dataset = { name: name, column: column, label: label ,data:props.uniquekey,point:point,columnmap:columnmap};
    
    props.onClickFn(dataset, widgetid);
  };

 

  if (layout === "statcard") {
    colors = ["#da1212d6", "#FF3E3E", "#F5C304", "#66BEA9"]; // Use first four colors for statcard layout
  } else {
    colors = [
      "#003F5C", "#004c6d", "#006083", "#007599", "#88c580", "#aed987", "#d6ec91", "#ffff9d", "#fee17e",
      "#eacc62","#00876c", "#3d9c73", "#63b179", "#fcc267", "#f7a258", "#ef8250", "#008bad", "#00a1c1",
      "#00b8d3", "#e4604e", "#d43d51"
    ];
  }

  useLayoutEffect(() => {
    let widgetheight = null;
    const width = hidex ? document.getElementById(container)?.closest(".bxDashboardGraph").clientWidth - 80 : null;

    if (chartContainer.current) {
      const parent = container.replace("bnfxWidgetContainer", "grid-stack-item");
      if (layout === "conciselayout") {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 70;
      } else {
        widgetheight = parseInt(document.getElementById(parent)?.clientHeight) - 120;
      }

      Highcharts.chart(chartContainer.current, {
        chart: {
          type: "bar",
          height: height ? height + "px" : widgetheight + "px",
          width: layout === "statcard" ? width : null,
          events: {
            load: function () {
              if (onclick_param === 'open_offcanvaspanel') {
              this.series[0]?.points.forEach((point) => {
                if (point.graphic && point.graphic.element) {
                  point.graphic.element.addEventListener("mousedown", () => {
                    point.graphic.element.setAttribute(
                      "data-bs-toggle",
                      "offcanvas"
                    );
                    point.graphic.element.setAttribute(
                      "data-bs-target",
                      "#bxOffcanvasPanel"
                    );
                    handleNameClick(point.category,point);
                  });
                }
              })};
            },
          },
        },
        title: {
          text: "",
          align: "left",
        },
        xAxis: {
          categories: categories,
          title: {
            text: null,
          },
          labels: {
            useHTML: true,
            formatter: function () {
              const label = this.value;
              const maxLength = 10; // Set the maximum length for the label
              const truncatedLabel =
                label.length > maxLength
                  ? label.substring(0, maxLength) + "..."
                  : label;
              return `<span title="${label}">${truncatedLabel}</span>`;
            },
            style: {
              color: "#b3771a",
              textOverflow: "ellipsis",
            },
          },
          gridLineWidth: 0,
          lineWidth: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
            align: "high",
          },
          labels: layout === "statcard" ? { enabled: false } : { overflow: "justify" },
          gridLineWidth: 0,
        },
        tooltip: {
          valueSuffix: "",
          pointFormat: ': {point.y}', // Display category and value without the series name
          style: {
            width: "200px", // Set popup width when the user hovers on the bar
            whiteSpace: "normal", // Ensure text doesn't overflow the tooltip
            wordWrap: "break-word", // Allow long words to break onto the next line
          },
          hideDelay: 100,
        },
        plotOptions: {
          bar: {
            pointWidth: layout === "statcard" ? 5 : 15,
            minPointLength: 5,
            dataLabels: {
              enabled: false,
              style: {
                fontFamily: "Arial",
                fontSize: "12px",
                fontWeight: "normal",
                color: "#FFF",
              },
            },
            groupPadding: 0.1,
            colorByPoint: true,
          },
          series: {
            borderWidth: 0,
          },
        },
        colors: colors,
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
          shadow: true,
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: series,
      });
    }
  }, [series, categories, container, height, layout, colors, hidex]);

  return <div ref={chartContainer} id={container} />;
};

export default BarChart;
