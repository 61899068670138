
function SummaryTab() {
    
  return (
    <>
      <div className="summarytabSec">
      <div className="d-flex align-items-start ">
        <div className="dooplerCardBoxRight_outer" id="bnfxTotalDetectionSummary">
         {/* we will render DetectionList */}
        </div>
      </div>
      </div>
    </>
  );
}


export default SummaryTab;
