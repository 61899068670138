import { useEffect } from "react";
import { Navigation } from "../../components/common/mainHeader/leftHeader/Navigation";
import { useLocation } from "react-router";
import DashboardWrapperContainer from "./DashboardWrapperContainer";

import "../dashboard/daterangepicker.css";
import "../dashboard/newdashboard.css";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";

function DashboardTemplate() {
  const location = useLocation();
  localStorage.removeItem("setScoreTimestamp");

  useEffect(() => {
    Navigation.onnavigate(location.pathname);
    CommonTemplate.removeSaveSearchModal();
  }, [location.pathname]);


  return (
    <>
      <div id="wrapper" className="bxContent DashboardPage">
        <div className="fullwidth main-inner" id="bxFullwidthContent">
          <div className="tab-content">
            <DashboardWrapperContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardTemplate;
