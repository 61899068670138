import React, { useEffect } from "react";
import { Incidence } from "../IncidencePanel/IncidenceScript";
import { t } from "i18next";
import { CommonTemplate } from "../containerAvailability/CommonScript";
import moment from "moment";
import { Automation } from "../Automations/AutomationScript";
import { PanelScript } from "./PanelScript";

function DashboardEntityList(props) {
  let output = props.result.data.response.result;
  let id = props.id;
  let data = props.data
  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  return (
    <div
      className="tab-pane fade show"
      id="entity-tab-pane"
      role="tabpanel"
      aria-labelledby="entity-tab"
      tabIndex="0"
    >
      {output.map((entry) => (
        <React.Fragment key={entry.entity}>
          <div className="bxDashboardAction_inner">
            <div className="bxDashboardAction_iETitle p-2">
              <div className="row align-items-center">
                <div className="col-2 entityBox">
                  <span className="text-mute">
                    {" "}
                    <i>
                      {moment(new Date(entry.scoretimestamp)).format(
                        "DD  MMM hh:mm"
                      )}
                    </i>
                  </span>
                  <h4 className="m-0 p-0">{CommonTemplate.formatNumber(entry.score)}</h4>
                  <small
                    className={CommonTemplate.criticalityColor(
                      entry.criticality
                    )}
                  >
                    {entry.criticality}
                  </small>
                </div>
                <div className="col-10">
                  <h5 className="BXf-14 pt-1 m-0">
                    {entry.entity}
                    <span className="material-symbols-outlined ms-2 incidentSearchIcon"
                    title="Search" 
                    onClick={() =>
                      Incidence.clickIncidentSearchIcon("entity", entry.entity,entry.scoretimestamp)
                    } 
                    >find_in_page</span>
                  </h5>

                  <p className="BXf-14 p-0">{t("title.nodescription")}</p>
                  <span
                    className="custom_BageStrem incidentclass"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#bxOffcanvasIncidence"
                  >
                    {" "}
                    <div
                      id={
                        "bnfxincidentid1" +
                        entry.entitytype +
                        entry.entity +
                        id
                      }
                      onClick={(e) => {
                        Incidence.loadmetadata("1"+entry.entitytype +
                          entry.entity +
                          id,"fromentitylist",data);
                        Incidence.showClass();CommonTemplate.removePanel();
                      }}
                    >
                      {" "}
                    </div>
                    {Incidence.getincidentid(
                      entry,
                      id,
                      props.streamid,
                      null,
                      null,
                      "bnfxincidentid",
                      true
                    )}
                  </span>
                  <span
                    className="custom_BageStrem incidentclass"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#bxOffcanvasIncidence"
                  >
                    {" "}
                    <div
                      id={
                        "bnfxincidentid2" +
                        entry.entitytype +
                        entry.entity +
                        id
                      }
                      onClick={(e) => {
                        Incidence.loadmetadata("2"+entry.entitytype +
                          entry.entity +
                          id,"fromentitylist",data);
                        Incidence.showClass();CommonTemplate.removePanel();
                      }}
                    >
                      {" "}
                    </div>
                  </span>
                </div>
              </div>
              <div className="dropdown ms-3">
                <button
                  className="dropdown-toggle arrow-none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                  more_vert
                  </i>
                </button>
                <ul
                  className="dropdown-menu"
                  data-popper-placement="bottom-start"
                >
                  <li
                    id={"creatincident" + entry.entitytype + entry.entity + id}
                  >
                    <button
                      className="dropdown-item bnfx_btn_create_incident"
                      id="createincidenttab"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        Incidence.loadformselectionoptions(
                          entry,
                          id,
                          props.streamid,
                          entry.entity,
                          entry.entitytype,
                          props.detectionname,
                          "entitylist"
                        );
                        Automation.offcanvasCloseButton();
                      }}
                    >
                      <span className="material-symbols-outlined">
                      destruction
                      </span>{" "}
                      {t("title.createincidence")}
                    </button>
                  </li>

                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bnfxdetctiongraph"
                      onClick={(e) => {
                        PanelScript.graphrender(
                          props.detectionname,
                          id,
                          entry.entity,
                          entry.entitytype,
                          props.tenant,
                          props.detectiontotal,
                          props.streamid
                        );
                        Automation.offcanvasCloseButton();
                      }}
                    >
                      <span className="material-symbols-outlined">tactic</span>{" "}
                      {t("title.attackpattern")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={(e) => {
                        PanelScript.convertrequireddteectiondata(
                          entry,
                          "entity",
                          props.streamid
                        );
                      }}
                    >
                      <span className="material-symbols-outlined">
                        genetics
                      </span>{" "}
                      {t("title.viewdetection")}
                    </button>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bnfxAutomationLayout"
                      onClick={() => {
                        Automation.loadAutomationList(
                          0,
                          entry.scoretimestamp,
                          props.detectionname,
                          entry.entity,
                          entry.entitytype,
                          props.streamid
                        );
                        CommonTemplate.showClass();
                        Automation.removeExecutionPanel();
                      }}
                    >
                      <span className="material-symbols-outlined">
                        playlist_play
                      </span>
                      {t("title.playbook.output")}
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bnfxPlaybookExecution"
                      onClick={() => {
                        Automation.loadPlaybookAutomationList(
                          0,
                          entry.entity,
                          entry.entitytype,
                          props.streamid,
                          id,
                          entry.score,
                          entry.tenant,
                          entry.scoretimestamp,
                          props.detectionname
                        );
                        CommonTemplate.showClass();
                        Automation.removeAutomationPanel();
                      }}
                    >
                      <span className="material-symbols-outlined">
                        playlist_add_check
                      </span>
                      {t("title.playbook.execution")}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default DashboardEntityList;
