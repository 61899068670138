import React, { useEffect, useRef } from "react";
import moment from "moment";
import $ from "jquery";
import "daterangepicker";

const DatePickerComponent = (props) => {
  const inputRef = useRef(null);
  const dashboardid = props.dashboardid;
  let newstartdate = null;
  let newenddate = null;
  let checkvalue = null;
  let data = localStorage.getItem(`date_${dashboardid}${props.id}`);
  let parsedValue = null;

  if (data) {
    checkvalue = JSON.parse(data);
  }

  if (checkvalue == null &&  props.data) {
      checkvalue = props.data;
  }

  if (checkvalue !== null) {
    if (checkvalue?.range !== undefined) {
      parsedValue = checkvalue?.range;
      if (parsedValue === "Today") {
        newstartdate = moment().startOf("day");
        newenddate = moment();
      } else if (parsedValue === "Yesterday") {
        newstartdate = moment().subtract(1, "days").startOf("day");
        newenddate = moment().subtract(1, "days").endOf("day");
      } else if (parsedValue === "Last 7 Days") {
        newstartdate = moment().subtract(6, "days");
        newenddate = moment();
      } else if (parsedValue === "Last 30 Days") {
        newstartdate = moment().subtract(29, "days");
        newenddate = moment();
      } else if (parsedValue === "This Month") {
        newstartdate = moment().startOf("month");
        newenddate = moment().startOf("hour");
      } else if (parsedValue === "Previous Month") {
        newstartdate = moment().subtract(1, "month").startOf("month");
        newenddate = moment().subtract(1, "month").endOf("month");
      } else if (parsedValue === "Last 24 Hours") {
        newstartdate = moment().subtract(1, "day");
        newenddate = moment();
      } else if (parsedValue === "Last 36 Hours") {
        newstartdate = moment().subtract(1, "day").subtract(12, "hours");
        newenddate = moment();
      } else if (parsedValue === "Last 48 Hours") {
        newstartdate = moment().subtract(2, "days");
        newenddate = moment();
      } else if (parsedValue === "Last 72 Hours") {
        newstartdate = moment().subtract(3, "days");
        newenddate = moment();
      }
    } else {
      parsedValue = checkvalue?.customdate;
      newstartdate = moment(parsedValue?.startdate, "YYYY-MM-DD HH:mm A");
      newenddate = moment(parsedValue?.enddate, "YYYY-MM-DD HH:mm A");
    }
  } else {
    newstartdate = null;
    newenddate = null;
  }
  const dateRanges = {};

  if (props.singleDate === false) {
    dateRanges["Today"] = [moment().startOf("day"), moment()];
    dateRanges["Yesterday"] = [
      moment().subtract(1, "days").startOf("day"),
      moment().subtract(1, "days").endOf("day"),
    ];
    dateRanges["Last 24 Hours"] = [moment().subtract(1, "day"), moment()];
    dateRanges["Last 36 Hours"] = [
      moment().subtract(1, "day").subtract(12, "hours"),
      moment(),
    ];
    dateRanges["Last 48 Hours"] = [moment().subtract(2, "days"), moment()];
    dateRanges["Last 72 Hours"] = [moment().subtract(3, "days"), moment()];
    dateRanges["Last 7 Days"] = [moment().subtract(6, "days"), moment()];
    dateRanges["Last 30 Days"] = [moment().subtract(29, "days"), moment()];
    dateRanges["This Month"] = [
      moment().startOf("month"),
      moment().startOf("hour"),
    ];
    dateRanges["Previous Month"] = [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ];
  }
  let scoretimestamp=localStorage?.getItem("setScoreTimestamp");
  let entityDetectnTimestamp =null;
  if(scoretimestamp){
    entityDetectnTimestamp = moment(Number(scoretimestamp)).format("hh:mm DD/MM/YYYY");
  }
  
  useEffect(() => {
    if (inputRef.current) {
      let elementid = $(inputRef.current).attr("id");
      $(inputRef.current).replaceWith($(inputRef.current)[0].outerHTML);
      inputRef.current = document.getElementById(elementid);

      const config = {
        // maxDate: props.noMax ? undefined : new Date(),
        timePicker: true,
        startDate: entityDetectnTimestamp || (newstartdate ? newstartdate : moment().subtract(1, "day")),
        endDate: newenddate ? newenddate : moment(),
        locale: {
          format: "hh:mm DD/MM/YYYY",
        },
        singleDatePicker: props.singleDate,
        ranges: dateRanges,
        minDate: props.beforeTodayDateHide ? moment().toDate() : undefined
      };

      if (config.singleDatePicker) {
        // config.showOneCalendar = true
        // If singleDatePicker is true, set the default date to endDate
        // config.startDate = config.endDate;
      } else {
        config.endDate = newenddate ? newenddate : moment();
      }

      $(inputRef.current).daterangepicker(config);

      props.onClickFn(inputRef.current);
    }
  }, [props.singleDate, newstartdate, newenddate, props, dateRanges,entityDetectnTimestamp]);

  return (
    <div className={props.className ? props.className : "bxDateTimePicker"}>
      <i className="material-symbols-outlined">calendar_month</i>

      <input
        type="text"
        name="datetimes"
        className="bxDateTimePickerInput"
        ref={inputRef}
        id={props.id}
      />
    </div>
  );
};

export default DatePickerComponent;
