import { useLayoutEffect } from "react";
import { Stream } from "../StreamScript";
import StreamDetailTab from "./StreamDetailTab";

function StreamDetailsLayout({ stream }) {

 
  useLayoutEffect(() => {

    Stream.loadDetailsLayout(stream);
  }, [stream]);
  

  return (
    <>

      <div className="bxStreamRightSide">
        <div id="bxSREDetails" className="bxStreamRightEventDetails">
          <div id="bnfxStreamMetaData">{/* here we replace metadata */}</div>
          
          <div id="bnfxCumulativeStatsStreamDetails">
            {/* here we replace CumulativeStats */}
          </div>
        </div>

       <StreamDetailTab/>
      </div>
    </>
  );
}

export default StreamDetailsLayout;
